import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import {I18n, Translate} from 'react-redux-i18n';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default ({
  onCancel,
  onConfirm,
  videoSize,
  clip
}) => {
  return (
    <Fragment>
      <DialogTitle id="download-dialog-title">
        {I18n.t('page.playerPage.downloadVideo.downloadConfirmation.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="download-dialog-description">
          <Translate
            value="page.playerPage.downloadVideo.downloadConfirmation.message"
            size={videoSize}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" id="playerDownloadCancel">
          {I18n.t('defaults.no')}
        </Button>
        <Button href={clip.url} target="_blank" color="secondary" onClick={onConfirm}>{I18n.t('defaults.yes')}</Button>
      </DialogActions>
    </Fragment>
  );
};
