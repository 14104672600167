/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {ELEMENT_VALUES} from '../constantFilters';
import StandardFilter from './StandardFilter';

export default class FilterDialog extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    showFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    filters: PropTypes.shape({
      oerTypeFilter: PropTypes.array,
      groupsFilter: PropTypes.array,
      terminalsFilter: PropTypes.array,
      mediaId: PropTypes.string
    }).isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      terminalsFilter: get(props.filters, 'terminalsFilter', []),
      groupsFilter: get(props.filters, 'groupsFilter', []),
      oerTypeFilter: get(props.filters, 'oerTypeFilter', []),
      mediaId: get(props.filters, 'mediaId', ''),
      filterElements: []
    };

    const elements = [
      {
        selected: true,
        name: 'OerTypes',
        id: 'filterOer',
        options: props.listOfOerTypes,
        label: 'filter.oer.label',
        value: this.getValue('filterOer'),
        onChange: this.handleOerSelection
      },
      {
        selected: true,
        name: 'Terminals',
        id: 'filterTerminal',
        options: props.listOfTerminals,
        label: 'filter.terminal.label',
        value: this.getValue('filterTerminal'),
        onChange: this.handleTerminalsSelection
      },
      {
        selected: true,
        name: 'Groups',
        id: 'filterGroup',
        options: props.listOfGroups,
        label: 'filter.group.label',
        value: this.getValue('filterGroup'),
        onChange: this.handleGroupSelection
      },
      {
        selected: true,
        name: 'Media Id',
        id: 'filterMediaId',
        label: 'filter.mediaId.label',
        value: this.getValue('filterMediaId'),
        onChange: this.handleMediaId
      }
    ];

    this.state.filterElements = elements.map(filter => {
      if (!this.props.showFilters.includes(filter.id)) {
        filter.selected = false;
      }
      return filter;
    });
  }

  componentWillReceiveProps(props) {
    const filterElements = this.state.filterElements.map(filter => {
      if (!this.props.showFilters.includes(filter.id)) {
        filter.selected = false;
      }

      if (filter.options) {
        filter.options = get(props, `listOf${filter.name}`, []);
      }

      filter.value = this.getValue(filter.id);
      return filter;
    });

    const terminalsFilter = get(props.filters, 'terminalsFilter', []);
    const groupsFilter = get(props.filters, 'groupsFilter', []);
    const oerTypeFilter = get(props.filters, 'oerTypeFilter', []);
    const mediaId = get(props.filters, 'mediaId', '');

    this.setState({
      filterElements,
      terminalsFilter,
      groupsFilter,
      oerTypeFilter,
      mediaId
    });
  }

  getValue(key) {
    return get(this.state, `${ELEMENT_VALUES[key]}`, []);
  }

  handleTerminalsSelection = value => {
    this.setState({terminalsFilter: value});
  }

  handleGroupSelection = value => {
    this.setState({groupsFilter: value});
  }

  handleOerSelection = value => {
    this.setState({oerTypeFilter: value});
  }

  handleMediaId = ({target}) => {
    this.setState({mediaId: target.value});
  }

  handleApply = () => {
    const {
      terminalsFilter,
      groupsFilter,
      oerTypeFilter,
      mediaId
    } = this.state;

    this.setState(
      {open: false},
      this.props.onApply({
        terminalsFilter,
        groupsFilter,
        oerTypeFilter,
        mediaId
      })
    );
  }

  handleClear = () => {
    this.setState({
      terminalsFilter: [],
      groupsFilter: [],
      oerTypeFilter: [],
      mediaId: ''
    }, this.props.onClear());
  }

  renderFilter(filter) {
    return (
      <StandardFilter
        name={filter.name}
        key={filter.id}
        id={filter.id}
        options={filter.options}
        label={filter.label}
        value={filter.value}
        onChange={filter.onChange}
      />
    );
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        BackdropProps={{
          invisible: true
        }}
        classes={{
          root: 'libraryfilter',
          paper: 'libraryfilter__paper'
        }}
      >
        <List className="librarylist desktopfilters__list">

          {
            this.state.filterElements.filter(filter => filter.selected).map(filter => this.renderFilter(filter))
          }

          <ListItem className="desktopfilters__actions">
            <Button
              id="filterClear"
              className="btn btn-secondary"
              variant="contained"
              onClick={this.handleClear}
            >
              {I18n.t('action.clear')}
            </Button>
            <Button
              id="filterApply"
              className="btn btn-primary"
              color="primary"
              variant="contained"
              onClick={this.handleApply}
            >
              {I18n.t('action.apply')}
            </Button>
          </ListItem>
        </List>
      </Dialog>
    );
  }
}

