import React from 'react';
import AlkMap from '../maps/components/AlkMap';

class MediaMap extends React.PureComponent {
  render() {
    /**
     * TTL-EU Trimble Maps license key
     * @type {string}
     */
    const ttlEuLicenseKey = "44A6C74FE1AEF54988BE740DB9709278";
    /**
     * Peoplenet Trimble Maps license key
     * @type {string}
     */
    const ppnetLicenseKey = "2A1BABD7DBB6294BA2D94D4D56D45326";
    const ttl = (localStorage.getItem("systemId")==="ttl");

    if(ttl)
      console.log("Using EU key");

    const {show, isMobile} = this.props.options;

    return (
      <div className={show ? 'mediamap' : 'hideme'}>
        {
          show &&
            <AlkMap
              apiKey={ttl?ttlEuLicenseKey:ppnetLicenseKey}
              center={this.props.location}
              oerMarker={this.props.location}
              oerItem={this.props.oerItem}
              breadcrumbData={this.props.data}
              playerSecond={this.props.playerSecond}
              isMobile={isMobile}
            />
        }
      </div>
    );
  }
}

export default MediaMap;
