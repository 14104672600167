/* eslint-disable quotes */
import React from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import NotificationDrawer from './components/NotificationDrawer';
import {getUnreadNotificationsCount} from './util/NotificationUtil';
import {openNotificationsPanel} from './NotificationActions';

class NotificationMenu extends React.PureComponent {
  handleOpenDrawer = () => {
    this.props.openNotificationsPanel();
  }

  render() {
    const {unreadNotificationsCount} = this.props;

    return (
      <>
        <IconButton
          color="inherit"
          onClick={this.handleOpenDrawer}
        >
          <Badge
            className="viappbar__badge-yellow"
            badgeContent={unreadNotificationsCount}
            max={99}
          >
            <Icon>notifications</Icon>
          </Badge>
        </IconButton>

        <NotificationDrawer />
      </>
    );
  }
}

const withRedux = connect(state => {
  return {
    unreadNotificationsCount: getUnreadNotificationsCount(get(state, 'notification.list', []))
  };
}, {
  openNotificationsPanel
});

export default withRedux(NotificationMenu);
