import React from 'react';
import {map} from 'lodash';
import Comment from './comments/Comment';
import CommentForm from './comments/CommentForm';


export default class Comments extends React.PureComponent {

  static defaultProps = {
    isMobile: false
  };

  render() {
    if (!this.props.visible) return null;
    const {entries} = this.props;
    return (
      <div className="reviewdesktop__cont">
        <div className="reviewdesktop__tab-container">
          <CommentForm
            userInformation={this.props.userInformation}
            postComment={this.props.postComment}
            mediaId={this.props.mediaId}
            isMobile={this.props.isMobile}
            commentText={this.props.commentText}
            onChangeCommentText={this.props.onChangeCommentText}
          />
          {map(entries, value =>
            <Comment
              {...value}
              userInformation={this.props.userInformation}
              key={value.id}
              postComment={this.props.postComment}
              deleteComment={this.props.deleteComment} 
            />
          )}
        </div>
      </div>
    );
  }
};

