import React from 'react';
import {I18n} from 'react-redux-i18n';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

export default class ErrorSnackbar extends React.Component {
  renderMessage() {
    let errorMessage = this.props.message;

    if (!errorMessage) {
      errorMessage = I18n.t('defaults.errorOcurredMessage');
    }

    return (
      <span
        style={{
          display: 'flex', alignItems: 'center'
        }}
      >
        <Icon style={{'margin-right': '8px'}}>error</Icon>
        {errorMessage}
      </span>
    );
  }

  renderCloseButton() {
    return (
      <IconButton onClick={this.props.onClose} color="inherit">
        <Icon>close</Icon>
      </IconButton>
    );
  }

  render() {
    let {autoHideDuration} = this.props;

    if (!autoHideDuration) {
      autoHideDuration = 6000;
    }

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={this.props.open}
        autoHideDuration={autoHideDuration}
        onClose={this.props.onClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#d32f2f'
          }}
          message={this.renderMessage()}
          action={[this.renderCloseButton()]}
        />
      </Snackbar>
    );
  }
}
