import React from 'react';
import MaskedInput from 'react-text-mask';

export default class TextMaskMinuteSeconds extends React.Component {
  render() {
    const {inputRef, ...other} = this.props;
    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={[/[0-3]/, /\d/, ':', /[0-5]/, /\d/]}
        placeholderChar='_' // eslint-disable-line jsx-quotes
        keepCharPositions
      />
    );
  }
}
