/* eslint-disable max-len */

import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ReactGA from 'react-ga';
import Button from '@material-ui/core/Button';
import {Icon} from '@material-ui/core';
import {I18n} from 'react-redux-i18n';
import setTag from '../videoTagging/videoTaggingActions';

const styleSheet = theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    background: theme.palette.background.paper
  }
});

class VideoTagging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      tagReview: false,
      reviewCompleted: false,
      coachCompleted: false
    };
  }

  componentWillMount() {
    const reviewCompleted = get(this.props.media.metadata, 'review.completed', false);
    const coachCompleted = get(this.props.coach, 'completed', false);
    const tagReview = get(this.props.media, 'tag.review.value', false);

    this.setState({
      tagReview,
      reviewCompleted,
      coachCompleted
    });
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.loading) {
      this.setState({updating: true});
    }
    if (nextProps.error) {
      this.setState({tagReview: !this.state.tagReview});
    }
    if (this.state.updating && !nextProps.loading && !nextProps.error) {
      this.setState({updating: false});
      this.props.onSuccess();
    } else {
      const tagReview = get(this.props.media, 'tag.review.value', false);

      if (tagReview !== this.state.tagReview) {
        this.setState({tagReview});
      }
    }
  };

  handleToggle(event, mediaId) {
    this.props.setTag(mediaId, {
      tag: 'review',
      value: !this.state.tagReview,
      coachCompleted: this.state.coachCompleted,
      reviewCompleted: this.state.reviewCompleted
    });
    this.setState({
      tagReview: !this.state.tagReview
    });

    ReactGA.event({
      category: 'Player',
      action: (!this.state.tagReview) ? 'Video Tagged' : 'Video Untagged',
      label: 'Video Tagging'
    });
  }

  render() {
    const {classes, isMobile} = this.props;
    if (isMobile) {
      return (
        <div className={classes.root}>
          <List>
            <ListItem className="tagitem">
              <ListItemIcon>
                <LocalOfferIcon className={this.state.tagReview ? 'tagged' : ''} />
              </ListItemIcon>
              <ListItemText primary={this.state.tagReview === false ? I18n.t('page.playerPage.videoTagging.notTagged') : I18n.t('page.playerPage.videoTagging.tagged')} />
              <ListItemSecondaryAction>
                <Switch
                  disabled={this.props.isInternalSalesUser}
                  onClick={event => this.handleToggle(event, this.props.media.mediaId)}
                  checked={this.state.tagReview !== false}
                  className="Switch"
                  id="videoTagSwitch"
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
      );
    }
    let text = I18n.t('page.playerPage.videoTagging.notTagged');
    let className = 'viappbar__tagvideoicon';
    if (this.state.tagReview === true) {
      text = I18n.t('page.playerPage.videoTagging.tagged');
      className = `${className}-active`;
    }
    return (
      <Button color="default" disabled={this.props.isInternalSalesUser} className="btn-terciery" onClick={event => this.handleToggle(event, this.props.media.mediaId)} id={`videoTag-${this.props.videoId}`}>
        <Icon className={className}>local_offer</Icon>{text}
      </Button>
    );
  }
}

VideoTagging.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  media: PropTypes.shape({}).isRequired,
  onSuccess: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const isMobile = get(state, 'configuration.isMobile', true);
  const {videoTagging} = state;
  return {videoTagging, isMobile};
}

export default connect(mapStateToProps, {setTag})(withStyles(styleSheet)(VideoTagging));
