export const FILTER_SEARCH_REQUEST = 'SEARCH_REQUEST_FILTER';
export const FILTER_SEARCH_SUCCESS = 'SEARCH_SUCCESS_FILTER';
export const FILTER_SEARCH_FAIL = 'SEARCH_FAIL_FILTER';
export const FILTER_UPDATE_CHIP = 'UPDATE_CHIP_FILTER';
export const LOAD_GROUPS_REQUEST = 'LOAD_GROUPS_REQUEST_FILTER';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS_FILTER';
export const LOAD_GROUPS_FAIL = 'LOAD_GROUPS_FAIL_FILTER';
export const APPLY_FILTER = 'SEARCH_APPLY_FILTER';
export const CLEAR_FILTER = 'SEARCH_CLEAR_FILTER';
export const LOAD_TERMINALS_REQUEST = 'LOAD_TERMINALS_REQUEST_FILTER';
export const LOAD_TERMINALS_SUCCESS = 'LOAD_TERMINALS_SUCCESS_FILTER';
export const LOAD_TERMINALS_FAIL = 'LOAD_TERMINALS_FAIL_FILTER';
export const LOAD_OER_FAIL = 'FILTER_LOAD_OER_FAIL_FILTER';
export const LOAD_OER_REQUEST = 'LOAD_OER_REQUEST_FILTER';
export const LOAD_OER_SUCCESS = 'LOAD_OER_SUCCESS_FILTER';
export const UNMOUNT_FILTER = 'UNMOUNT_FILTER';
export const CONSTRAINED_FILTERS = 'CONSTRAINED_FILTERS_FILTER';
export const ADD_DRIVER_FILTER_CLICK = 'ADD_DRIVER_FILTER_CLICK_FILTER';
export const ADD_VEHICLE_FILTER_CLICK = 'ADD_VEHICLE_FILTER_CLICK_FILTER';
export const ADD_TERMINAL_FILTER_CLICK = 'ADD_TERMINAL_FILTER_CLICK_FILTER';
export const ADD_OER_FILTER_CLICK = 'ADD_OER_FILTER_CLICK_FILTER';
export const SET_ON_DEMAND_FILTER = 'SET_ON_DEMAND_FILTER_FILTER';
