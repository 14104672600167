import React from 'react';
import {I18n} from 'react-redux-i18n';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class CancelMediaRequestDialog extends React.Component {

  render() {
    return (
      <Dialog
        open={this.props.open}
      >
        <DialogTitle className="dialog-titledanger">
          {I18n.t('page.onDemand.requestQueue.cancelConfirmation.title')}
        </DialogTitle>

        <DialogContent className="dialog-content">
          <DialogContentText>
            {I18n.t('page.onDemand.requestQueue.cancelConfirmation.message')}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="ondemand__dialog-actions">
            <Button
              className="btn btn-secondary"
              onClick={this.props.onClose}
              disabled={this.props.loading}
            >
              {I18n.t('defaults.no')}
            </Button>

            <Button
              className="btn btn-danger"
              onClick={this.props.onCancelMedia}
              disabled={this.props.loading}
            >
              {I18n.t('defaults.yes')}
            </Button>
          </DialogActions>
      </Dialog>
    );
  }

}
