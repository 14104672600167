import React from 'react';
import {I18n} from 'react-redux-i18n';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {get, camelCase} from 'lodash';
import PointChip from './PointChip';
import ReviewCompletedChip from './ReviewCompletedChip';
import CustomReview from './ReviewCustom';

const biggerItem = {lg: 12, md: 12, sm: 12, xs: 12};
const smallerItem = {lg: 4, md: 4, sm: 6, xs: 12};

const getReviewPoints = category => get(category, 'reviewOptions', []);

const createReviewCheckboxId = reviewReason => {
  const categoryName = camelCase(reviewReason.categoryName);
  const reviewName = camelCase(reviewReason.name);
  return `${categoryName}_${reviewName}`;
};

const ReviewForm = ({
  review: {
    mainCategory,
    otherCategories,
    completed,
    edited,
    showReviewSubmittedChip
  },
  handleCustomReviewChange,
  handleCheckReviewOption,
  handleReviewSubmit,
  openCloseReviewSubmittedChip,
  handleCheckReviewCompleted,
  cancelEditReview,
  showScore,
  canSubmit
}) => {
  const renderMainCategory = reviewReason => {
    if (reviewReason.custom) {
      return (
        <Grid item {...smallerItem} key="custom" className="reviewdesktop__checkcontainer reviewdesktop__customreview">
          <CustomReview
            id={camelCase(reviewReason.categoryName)}
            onChange={handleCustomReviewChange}
            value={reviewReason.name}
            points={reviewReason.points}
            scoreEnable={showScore}
          />
        </Grid>
      );
    }

    return (
      <Grid item {...smallerItem} key={reviewReason.name} className="reviewdesktop__checkcontainer">
        <Checkbox
          id={createReviewCheckboxId(reviewReason)}
          className="reviewdesktop__checkbox"
          onClick={() => handleCheckReviewOption(reviewReason.categoryName, reviewReason.name)}
          checked={reviewReason.marked}
        />
        {I18n.t(reviewReason.nameId, reviewReason.name)}
      </Grid>
    );
  };
  return (
    <div className="reviewdesktop__tab-container">
      <Grid container>
        <Grid item {...biggerItem} key={mainCategory.categoryName} className="reviewdesktop__checkcontainer">
          <h2>
            {I18n.t(mainCategory.categoryNameId, mainCategory.categoryName)} <PointChip points={mainCategory.points} display={showScore} />
          </h2>
        </Grid>
        {getReviewPoints(mainCategory).map(renderMainCategory)}
      </Grid>

      <Grid container>
        {otherCategories && otherCategories.map(category => (
          <Grid item {...smallerItem} key={category.categoryName} className="reviewdesktop__checkcolumn">
            <Grid container>
              <Grid item {...biggerItem} className="reviewdesktop__checkcontainer">
                <h2>{I18n.t(category.categoryNameId, category.categoryName)}</h2>
              </Grid>
              {getReviewPoints(category).map(reviewReason => (
                <Grid item {...biggerItem} key={reviewReason.name} className="reviewdesktop__checkcontainer">
                  <Checkbox
                    id={createReviewCheckboxId(reviewReason)}
                    className="reviewdesktop__checkbox"
                    onClick={() => handleCheckReviewOption(reviewReason.categoryName, reviewReason.name)}
                    checked={reviewReason.marked}
                  />
                  {I18n.t(reviewReason.nameId, reviewReason.name)}
                  <PointChip points={reviewReason.points} display={showScore} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>

      {showReviewSubmittedChip &&
        <ReviewCompletedChip handleReviewSubmittedChip={openCloseReviewSubmittedChip} />}

      <Grid container>
        <Grid item {...biggerItem} className="reviewdesktop__actions" >
          <label id="reviewCompleteCheckbox">
            {I18n.t('page.playerPage.reviewForm.reviewComplete')}
            <Checkbox
              id="reviewCompleted"
              onClick={handleCheckReviewCompleted}
              checked={completed}
            />
          </label>
          <Button
            id="cancelEditReviewButton"
            variant="contained"
            color="default"
            className="btn btn-secondary"
            onClick={cancelEditReview}
          >
            {I18n.t('page.playerPage.reviewForm.cancel')}
          </Button>
          <Button
            id="submitReviewButton"
            variant="contained"
            color="default"
            className="btn btn-primary"
            disabled={!canSubmit || !edited}
            title={!canSubmit && I18n.t('defaults.noPermissionFunctionality')}
            onClick={handleReviewSubmit}
          >
            {I18n.t('page.playerPage.coachingForm.submit')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

ReviewForm.defaultProps = {
  review: {
    mainCategory: {},
    otherCategories: [],
    completed: false,
    edited: false,
    showReviewSubmittedChip: false
  }
};

export default ReviewForm;
