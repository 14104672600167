export const FILTER_TERMINAL = 'filterTerminal';
export const FILTER_GROUP = 'filterGroup';
export const FILTER_OER = 'filterOer';
export const FILTER_MEDIAID = 'filterMediaId';
export const ELEMENT_VALUES = {
  filterOer: 'oerTypeFilter',
  filterTerminal: 'terminalsFilter',
  filterGroup: 'groupsFilter',
  filterMediaId: 'mediaId'
};
export const DATE_FILTER_PERIODS = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  LAST_SEVEN_DAYS: 'Last 7 Days',
  LAST_WEEK: 'Last Week',
  LAST_THIRTY_DAYS: 'Last 30 Days',
  LAST_MONTH: 'Last Month',
  THREE_MONTHS: '3 Months',
  SIX_MONTHS: '6 Months'
}
