import React from 'react';
import {Pie} from 'react-chartjs-2';


export default class PieGraph extends React.PureComponent {
  render() {
    const {
      labels,
      data
    } = this.props;

    const datasets = [{
      data,
      backgroundColor: [
        '#97bbcd',
        '#dcdcdc',
        '#f7464a',
        '#46bfbd',
        '#fdb45c',
        '#949fb1',
        '#4d535f'
      ],
      hoverBackgroundColor: [
        '#97bbcd',
        '#dcdcdc',
        '#f7464a',
        '#46bfbd',
        '#fdb45c',
        '#949fb1',
        '#4d535f'
      ]
    }];

    const options = {
      legend: {
        display: true,
        onClick: function(e) { e.stopPropagation(); }
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            var allDatas = data.datasets[tooltipItem.datasetIndex].data;
            var value = allDatas[tooltipItem.index];
            var total = allDatas.reduce(function (a, b) { return parseInt(a) + parseInt(b); }, 0);

            var percentage = (value / total * 100).toFixed(2);

            return data.labels[tooltipItem.index] + ' ' + percentage + '%';
          },
        }
      }
    };

    return (
      <div>
        <Pie data={{labels, datasets}} options={options} />
      </div>
    );
  }
}
