import React from 'react';
import {I18n} from 'react-redux-i18n';

const SEVERITY = 'severity';
const NEUTRAL = 'neutral';
const RECOGNITION = 'recog';

const getStyle = points => {
  if (points > 0) {
    return SEVERITY;
  } else if (points < 0) {
    return RECOGNITION;
  }
  return NEUTRAL;
};

const PointChip = ({points, display = false}) => {
  const style = getStyle(points);

  return (
    <span
      className={`reviewdesktop__pointchip reviewdesktop__pointchip-${style}`}
      style={{
        display: display ? '' : 'none'
      }}
    >
      {points} {I18n.t('page.playerPage.reviewDetails.points')}
    </span>
  );
};

export default PointChip;
