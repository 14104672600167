const {navigator} = window;
const {URL} = window;

class AbstractFileDownloader {
  data = null

  constructor(data) {
    this.data = data;
  }

  // eslint-disable-next-line no-unused-vars
  downloadAs(fileName) { }
}

class MSFileDownloader extends AbstractFileDownloader {
  downloadAs(fileName) {
    navigator.msSaveBlob(this.data, fileName);
  }

  static canBeUsed() {
    return typeof navigator.msSaveBlob !== 'undefined';
  }
}

class LinkFileDownloader extends AbstractFileDownloader {
  downloadAs(fileName) {
    const blobURL = URL.createObjectURL(this.data);

    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();

    document.body.removeChild(tempLink);
    URL.revokeObjectURL(blobURL);
  }
}

export default class FileDownloader {
  static forData(data) {
    if (MSFileDownloader.canBeUsed()) {
      return new MSFileDownloader(data);
    }

    return new LinkFileDownloader(data);
  }
}
