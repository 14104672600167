import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Icon from '@material-ui/core/Icon';
import ReactGA from 'react-ga';
import {I18n} from 'react-redux-i18n';
import Badge from '@material-ui/core/Badge';

const styleSheet = () => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: '0',
    left: '0'
  }
});

class PlayerBottomNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 1
    };
  }

  static defaultProps = {
    commentsSize: 0
  }

  handleChange = (event, value) => {
    this.setState({value});
  };

  handleClickVideo = event => {
    this.props.options.events.onClickVideo(event);
    this.handleGA('Video Tab');
  };

  handleClickDashboard = event => {
    this.props.options.events.onClickDashboard(event);
    this.handleGA('Dashboard Tab');
  };

  handleClickMap = event => {
    this.props.options.events.onClickMap(event);
    this.handleGA('Map Tab');
    window.scrollTo({
      'behavior': 'auto',
      'left': 0,
      'top': 0
    });
  };

  handleClickComments = event => {
    this.props.options.events.onClickComments(event);
    this.handleGA('Comments Tab');
    window.scrollTo({
      behavior: 'auto',
      left: 0,
      top: 0
    });
  };

  handleGA = tabSelected => {
    ReactGA.event({
      category: 'Player',
      action: tabSelected,
      label: 'Bottom Nav'
    });
  }

  renderBadge = () => {
    const {commentsSize} = this.props;
    if (commentsSize > 0) {
      return (
        <Fragment>
          <Badge className="bottomnav-badge" badgeContent={commentsSize} />
        </Fragment>
      );
    }
    return (<Icon>chat_bubble</Icon>);
  };

  render() {
    const {classes} = this.props;
    const {value} = this.state;

    return (
      <BottomNavigation
        value={value}
        onChange={this.handleChange}
        showLabels
        className={this.props.options.show ? classes.root : 'hideme'}
      >
        <BottomNavigationAction id="playerNavigationVideo" label={I18n.t('page.playerPage.PlayerBottomNav.video')} icon={<Icon>play_circle_filled</Icon>} onClick={this.handleClickVideo} className="bottomnav-button" />
        <BottomNavigationAction id="playerNavigationDashboard" label={I18n.t('page.playerPage.PlayerBottomNav.dashboard')} icon={<Icon>local_shipping</Icon>} onClick={this.handleClickDashboard} className="bottomnav-button" />
        <BottomNavigationAction id="playerNavigationMap" label={I18n.t('page.playerPage.PlayerBottomNav.map')} icon={<Icon>map</Icon>} onClick={this.handleClickMap} className="bottomnav-button" />
        <BottomNavigationAction
          id="playerNavigationComment"
          label={I18n.t('page.playerPage.PlayerBottomNav.comments')}
          icon={this.renderBadge()}
          onClick={this.handleClickComments}
          className="bottomnav-button"
        />
      </BottomNavigation>
    );
  }
}

PlayerBottomNav.propTypes = {
  classes: PropTypes.shape({}).isRequired
};

// export default connect(mapStateToProps)(PlayerBottomNav);
export default withStyles(styleSheet)(PlayerBottomNav);
