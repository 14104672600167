import {get, lowerCase, sortBy} from 'lodash';
import BaseService from '../../middleware/BaseService';

const SEARCH_ENDPOINT = '/customers/list';

class SwitchCustomerService extends BaseService {
  prefix = SEARCH_ENDPOINT;

  async loadCustomers() {
    const response = await this.search();
    let customers = get(response, 'data', []);
    customers = sortBy(customers, c => lowerCase(c.name));
    return customers;
  }
}

export default new SwitchCustomerService();
