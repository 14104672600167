import {concat, find, isArray, isEqual, toLower, each, size} from 'lodash';
import moment from 'moment';
import {
  CONSTRAINED_FILTERS,
  FILTER_SEARCH_REQUEST,
  FILTER_SEARCH_SUCCESS,
  FILTER_SEARCH_FAIL,
  FILTER_UPDATE_CHIP,
  LOAD_GROUPS_REQUEST,
  LOAD_GROUPS_SUCCESS,
  LOAD_GROUPS_FAIL,
  LOAD_TERMINALS_REQUEST,
  LOAD_TERMINALS_SUCCESS,
  LOAD_TERMINALS_FAIL,
  LOAD_OER_REQUEST,
  LOAD_OER_SUCCESS,
  LOAD_OER_FAIL,
  UNMOUNT_FILTER,
  APPLY_FILTER,
  CLEAR_FILTER,
  ADD_DRIVER_FILTER_CLICK,
  ADD_VEHICLE_FILTER_CLICK,
  ADD_TERMINAL_FILTER_CLICK,
  ADD_OER_FILTER_CLICK,
  SET_ON_DEMAND_FILTER
} from './filterActionTypes';
import FilterService from '../service/FilterService';

export const searchDriverVehicle = typedText => async dispatch => {
  const {text} = typedText;
  const typedVehicle = {id: text, name: text, type: 'vehicle'};

  dispatch({type: FILTER_SEARCH_REQUEST, payload: [typedVehicle]});
  try {
    let driversAndVehicles = await FilterService.searchDriverVehicle(typedText);

    if (!driversAndVehicles) {
      driversAndVehicles = [typedVehicle];
    } else if (!find(driversAndVehicles, it => isEqual(toLower(it.name), toLower(text)) && it.type === 'vehicle')) {
      driversAndVehicles = concat(typedVehicle, driversAndVehicles);
    }

    dispatch({type: FILTER_SEARCH_SUCCESS, payload: driversAndVehicles});
  } catch (e) {
    console.error('Unable to get drivers and vehicles list', e);
    dispatch({type: FILTER_SEARCH_FAIL});
  }
};

export const updateConstrainedFilters = newFilters => dispatch => {
  dispatch({type: CONSTRAINED_FILTERS, payload: newFilters});
};

export const constrainedFiltersConverter = (constrainedFilters = {}) => {
  const filters = {};
  if (constrainedFilters.reviewed) {
    filters.reviewed = true;
  } else if (constrainedFilters.unreviewed) {
    filters.reviewed = false;
  }

  if (constrainedFilters.onDemand) {
    filters.fetched = true;
  } else {
    filters.fetched = 'oeronly';
  }

  if (constrainedFilters.reviewedComplete) {
    return {reviewCompleted: true};
  }

  if (constrainedFilters.coachingCompleted) {
    filters.coached = true;
  }

  if (constrainedFilters.flagged) {
    filters.flagged = true;
  }

  if (constrainedFilters.tagged) {
    filters.tag = 'review';
  }

  return filters;
};

export const applyFilter = selectedFilters => {
  return dispatch => {
    const {
      chipsFilter,
      groupsFilter,
      constrainedFilters,
      terminalsFilter,
      oerTypeFilter,
      startTime,
      endTime,
      sortBy,
      mediaId,
      suddenstoptype,
      smartReview
    } = selectedFilters || {};

    let filters = {};

    const contrained = constrainedFiltersConverter(constrainedFilters);
    filters = {...filters, ...contrained};

    if (startTime) {
      filters.startTime = moment(startTime).utc().valueOf();
    }

    if (endTime) {
      filters.endTime = moment(endTime).utc().valueOf();
    }

    if (isArray(groupsFilter) && size(groupsFilter) > 0) {
      filters.group = groupsFilter;
    }

    if (isArray(terminalsFilter) && size(terminalsFilter) > 0) {
      filters.terminal = terminalsFilter;
    }

    if (suddenstoptype) {
      filters.suddenstoptype = suddenstoptype;
    }

    if (sortBy) {
      filters.sortBy = sortBy;
    }

    if (isArray(oerTypeFilter) && size(oerTypeFilter) > 0) {
      filters.trigger = oerTypeFilter;
      if (filters.fetched === 'oeronly') {
        delete filters.fetched;
      }
    }

    if (isArray(chipsFilter) && size(chipsFilter) > 0) {
      each(chipsFilter, vehicleDriver => {
        const {name, value} = vehicleDriver;
        filters[name] = value;
      });
    }

    if (mediaId) {
      filters.mediaId = mediaId;
    }

    if (smartReview !== null) {
      filters.smartReview = JSON.stringify(smartReview);
    }

    return dispatch({
      type: APPLY_FILTER,
      filters,
      selectedFilters: {
        chipsFilter,
        groupsFilter,
        constrainedFilters,
        terminalsFilter,
        oerTypeFilter,
        startTime,
        endTime,
        sortBy,
        suddenstoptype,
        smartReview
      }
    });
  };
};

export const clearFilter = () => {
  return dispatch => {
    return dispatch({type: CLEAR_FILTER});
  };
};

export const loadGroups = () => async dispatch => {
  dispatch({type: LOAD_GROUPS_REQUEST});
  try {
    const groups = await FilterService.loadGroups();
    dispatch({type: LOAD_GROUPS_SUCCESS, payload: groups});
    return groups;
  } catch (e) {
    console.error('Unable to get groups list', e);
    dispatch({type: LOAD_GROUPS_FAIL});
  }
};

export const loadTerminals = () => async dispatch => {
  dispatch({type: LOAD_TERMINALS_REQUEST});
  try {
    const terminals = await FilterService.loadTerminals();
    dispatch({type: LOAD_TERMINALS_SUCCESS, payload: terminals});
    return terminals;
  } catch (e) {
    console.error('Unable to get terminals list', e);
    dispatch({type: LOAD_TERMINALS_FAIL});
  }
};

export const loadOerTypes = params => async dispatch => {
  dispatch({type: LOAD_OER_REQUEST});

  try {
    const oers = await FilterService.loadOERs(params);
    dispatch({type: LOAD_OER_SUCCESS, payload: oers});
  } catch (e) {
    console.error('Unable to get event list', e);
    dispatch({type: LOAD_OER_FAIL});
  }
};

export const updateChipFilter = chipFilter => dispatch => {
  dispatch({
    type: FILTER_UPDATE_CHIP,
    payload: chipFilter
  });
};

export const clear = () => dispatch => {
  debugger;
  dispatch({type: UNMOUNT_FILTER});
};

export const addFilterChipOnDriverClick = ({driver, driverId}) => dispatch =>
  dispatch({
    type: ADD_DRIVER_FILTER_CLICK,
    payload: {
      driver,
      driverId,
      label: `${driver} (${driverId})`
    }
  });

export const addFilterChipOnVehicleClick = vehicle => dispatch =>
  dispatch({
    type: ADD_VEHICLE_FILTER_CLICK,
    payload: {
      vehicle
    }
  });

export const addFilterChipOnTerminalClick = terminal => dispatch =>
  dispatch({
    type: ADD_TERMINAL_FILTER_CLICK,
    payload: {
      terminal
    }
  });

export const addFilterOnOERClick = oer => dispatch =>
  dispatch({
    type: ADD_OER_FILTER_CLICK,
    payload: {
      oer: toLower(oer)
    }
  });


export const setOnDemandFilter = payload => dispatch => {
  dispatch({type: SET_ON_DEMAND_FILTER, payload});
};

export const addOnDemandFilter = () => dispatch => {
  setOnDemandFilter(true)(dispatch);
};
