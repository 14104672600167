import {get} from 'lodash';
import {
  CUSTOMER_SEARCH_REQUEST,
  CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_SEARCH_FAIL,
  CLEAR_SWITCH_ACCOUNTS
} from './SwitchCustomerActionsType';


const initialState = {
  customers: [],
  error: false,
  loading: false
};

export default function reducer(state = initialState, action) {
  const {type} = action || {};
  switch (type) {
    case CUSTOMER_SEARCH_REQUEST:
      return {...state, error: false, loading: true, customers: []};
    case CUSTOMER_SEARCH_FAIL:
      return {...state, error: true, loading: false, customers: []};
    case CUSTOMER_SEARCH_SUCCESS:
      return {...state, error: false, loading: false, customers: get(action, 'payload', [])};
    case CLEAR_SWITCH_ACCOUNTS:
      return {...state, ...initialState};
    default:
      return state;
  }
}
