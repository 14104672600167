import {concat, filter, flatMap, get, groupBy, map, pick, size, sortBy, toLower, trim, unset} from 'lodash';
import {I18n} from 'react-redux-i18n';
import BaseService from '../../../middleware/BaseService';

const SEARCH_ENDPOINT = '/search/autocomplete/all';
const GROUPS_ENDPOINT = '/groups';
const OER_ENDPOINT = '/medias/oer';
const TERMINALS_ENDPOINT = '/terminals';

class FilterService extends BaseService {
  prefix = SEARCH_ENDPOINT;

  sortByName = list => sortBy(list, it => trim(toLower(get(it, 'name'))));

  async searchDriverVehicle(params) {
    if (params && params.text.length > 2) {
      const response = await this.search(params, SEARCH_ENDPOINT);
      return map(get(response, 'data', []), e => ({
        id: e.id,
        name: (e.name) || e.number,
        type: e.type
      }));
    }
    return [];
  }

  async loadGroups() {
    const response = await this.search('', GROUPS_ENDPOINT);
    let groups = get(response, 'data', []);
    groups = filter(groups, item => item.parentGroupId !== '-1');
    groups = map(groups, item => {
      const {id, name, parentGroupId} = item;
      return {id, name, parentGroupId};
    });
    groups = groupBy(groups, 'parentGroupId');

    const cleanObj = obj => map(obj, it => pick(it, 'id', 'name'));

    const getGroupChildren = value => {
      const {id, name} = value;
      const children = this.sortByName(cleanObj(get(groups, id, [])));
      if (size(children) > 0) {
        const unassigned = {id, name: I18n.t('filter.group.unassigned'), selectedLabel: `${name} (${I18n.t('filter.group.unassignedSelected')})`};
        unset(groups, id);
        return {id: `root${id}`, name, root: true, children: concat(unassigned, children)};
      }
      return {id, name, children};
    };

    groups = flatMap(groups, entry => flatMap(entry, getGroupChildren));
    return this.sortByName(groups);
  }

  async loadTerminals() {
    const response = await this.search({deleted: false}, TERMINALS_ENDPOINT);

    const terminals = map(get(response, 'data', []), item => {
      const {region, id, name} = item;
      const terminal = {id, name};
      if (region) {
        terminal.parentGroupId = region;
        terminal.parentGroupName = region;
      } else {
        terminal.parentGroupId = '0';
        terminal.parentGroupName = 'All Terminals';
      }
      return terminal;
    });

    const sortedList = this.sortByName(terminals, 'name');
    const groupedList = groupBy(sortedList, 'parentGroupId');
    return flatMap(groupedList, (value, key) => {
      const sorted = this.sortByName(value);
      if (key !== '0') {
        return {id: key, name: key, root: true, children: map(sorted, it => pick(it, 'id', 'name'))};
      }
      return map(sorted, it => pick(it, 'id', 'name'));
    });
  }

  async loadOERs(_params) {
    const response = await this.search(_params, OER_ENDPOINT);
    const oers = map(get(response, 'data.oer.buckets', []), item => {
      const {key} = item;
      const name = key;
      return {id: key, name};
    });
    return this.sortByName(oers);
  }
}

export default new FilterService();
