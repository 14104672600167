import {get} from 'lodash';
import {
  REQUEST_SUMMARY_INFORMATION,
  REQUEST_SUMMARY_INFORMATION_SUCCESS,
  REQUEST_SUMMARY_INFORMATION_FAILURE
} from './DashboardActionTypes';

const initialState = {
  summaryInformation: {
    avgDistance: 0,
    totalReviewed: 0,
    totalCoached: 0,
    eventsGenerated: 0
  },
  reviewsAndOers: [],
  distance: [],
  topOers: [],
  industryDistanceAverage: [],
  topCoachBehaviors: [],
  oers: {},
  isLoading: true
};

const getavgDistance = payload => {
  if (payload.distance.totalDistance.value === 0 || payload.oers.total === 0) {
    return 0;
  }

  return (payload.distance.totalDistance.value / payload.oers.total).toFixed(1);
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case REQUEST_SUMMARY_INFORMATION:
      return {
        ...state,
        isLoading: true
      };
    case REQUEST_SUMMARY_INFORMATION_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case REQUEST_SUMMARY_INFORMATION_SUCCESS: {
      return {
        ...state,
        summaryInformation: {
          avgDistance: getavgDistance(payload),
          totalReviewed: payload.totalReviews,
          totalCoached: payload.totalCoachCompleted,
          eventsGenerated: payload.oers.total
        },
        reviewsAndOers: payload.reviewsAndOers,
        distance: get(payload.distance, 'dates.buckets', []),
        topOers: get(payload, 'topOers', []),
        industryDistanceAverage: get(payload, 'industryDistanceAverage', []),
        topCoachBehaviors: get(payload, 'topCoachBehaviors', []),
        oers: payload.oers,
        isLoading: false
      };
    }
    default:
      return state;
  }
};
