import React, {PureComponent} from 'react';
import {I18n} from 'react-redux-i18n';

class ReviewCompletedChip extends PureComponent {
  componentDidMount() {
    this.timeoutFunction = setTimeout(
      () => this.props.handleReviewSubmittedChip(),
      5000
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeoutFunction);
  }

  render() {
    return (
      <div className="reviewdesktop__feedbackmsg">
        <p>{I18n.t('page.playerPage.reviewForm.reviewSaved')}</p>
      </div>
    );
  }
}

export default ReviewCompletedChip;
