import React from 'react';
import {I18n} from 'react-redux-i18n';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';


class CustomReview extends React.Component {
  static defaultProps = {
    value: ''
  }

  createComponentId(suffixId) {
    const {id} = this.props;

    if (!id) {
      return suffixId;
    }

    return `${id}_${suffixId}`;
  }

  handleChange = ({target}) => {
    const {value} = target;
    if (value && value.length < 33) {
      this.props.onChange(value, this.props.points > 0);
    }
  }

  handleScoringChange = (target, checked) => {
    this.props.onChange(this.props.value, checked);
  }

  renderSwitch = () => {
    if (!this.props.scoreEnable) {
      return null;
    }

    return (
      <Switch
        id={this.createComponentId('scoring')}
        onChange={this.handleScoringChange}
        checked={this.props.points > 0}
      />
    );
  }

  render() {
    const useScore = this.props.points > 0;
    const {scoreEnable} = this.props;
    const helperText = useScore ? I18n.t('page.playerPage.reviewForm.custom.scoring') : I18n.t('page.playerPage.reviewForm.custom.notScoring');
    const className = useScore ? 'feedbackmsg__success' : 'feedbackmsg__warning';
    return (
      <React.Fragment>
        <TextField
          id={this.createComponentId('customReview')}
          onChange={this.handleChange}
          label={I18n.t('page.playerPage.reviewForm.custom.label')}
          value={this.props.value}
          helperText={scoreEnable && <span className={className}>{helperText}</span>}
          inputProps={{
            maxLength: 32
          }}
        />
        {this.renderSwitch()}
      </React.Fragment>
    );
  }
}

export default CustomReview;
