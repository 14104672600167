
import React, {Fragment} from 'react';
import ReactGA from 'react-ga';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Translate} from 'react-redux-i18n';
import Icon from '@material-ui/core/Icon';
import {get} from 'lodash';
import MediaListViewItem from './MediaListViewItem';

export default class MediaListListView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allChecked: false,
      isInternal: get(this.props, 'user.roles.videoIntelligenceInternal', false),
      isAdmin: get(this.props, 'user.roles.videoIntelligenceAdmin', false) ||
      get(this.props, 'user.changeCustomer', false),
      isManager: get(this.props, 'user.roles.videoIntelligenceManager', false),
      listViewCsvIsLoading: false
    };

    this.state.isBasicUser = (!this.state.isInternal &&
      !this.state.isAdmin && !this.state.isManager);
  }

  componentWillReceiveProps(nextProps) {
    const {listViewCsv, listViewCsvIsLoading} = nextProps;

    if (listViewCsv) {
      this.downloadListViewCsv(listViewCsv);
      this.props.clearDownloadListViewCsv();
    }

    this.setState({listViewCsvIsLoading});
  }

  isDeleteEnable = medias => {
    return medias.filter(media => media.checked).length > 0;
  }

  downloadListViewCsv = listViewCsv => {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(listViewCsv, 'report.csv');
    } else {
      const blobURL = window.URL.createObjectURL(listViewCsv);
      const tempLink = document.createElement('a');

      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', 'report.csv');
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }
  }

  handleClick = mediaId => () => {
    this.props.onVideoClick(mediaId)();

    ReactGA.event({
      category: 'Library',
      action: 'Open Player Page',
      label: 'List Item'
    });
  }

  handleMassDelete = () => {
    if (this.isDeleteEnable(this.props.medias) && !this.state.isInternal) {
      return this.props.onDeleteVideo();
    }
    return false;
  }

  deleteVideo = idx => {
    if (!this.state.isInternal) {
      const media = this.props.medias[idx];
      if (media) {
        this.props.onDeleteVideo(media);
      }
    }
  }

  handleDownloadListViewAsCsvClick = () => {
    this.props.downloadListViewAsCsv();
  }

  checkMedia = idx => () => {
    const media = this.props.medias[idx];
    if (media) {
      this.props.onCheckMedia(idx, !media.checked);
    }
  }

  checkAll = () => {
    const allChecked = !this.state.allChecked;
    this.props.onCheckMedia('all', allChecked);
    this.setState({allChecked});
  }

  renderLine = (media, index) => {
    return (
      <MediaListViewItem
        media={media}
        index={index}
        onVideoClick={this.props.onVideoClick}
        onCheckMedia={this.props.onCheckMedia}
        prepareReference={this.props.prepareReference}
        key={media.mediaId}
        checked={this.state.allChecked}
        locale={this.props.locale}
        deleteVideo={this.deleteVideo}
        isInternal={this.state.isInternal}
        isAdmin={this.state.isAdmin}
        isManager={this.state.isManager}
        isBasicUser={this.state.isBasicUser}
      />
    );
  }

  renderMassDelete = () => {
    const isAdminOrInternal = (this.state.isAdmin || this.state.isInternal);

    return (
      <Fragment>
        {isAdminOrInternal &&
          <TableCell className="library__checkboxcell">
            <Checkbox
              disabled={this.state.isInternal}
              onClick={this.checkAll}
              checked={this.state.allChecked}
            />
          </TableCell>
        }
        {!this.state.isBasicUser &&
          <TableCell className="library__deletecell">
            {isAdminOrInternal &&
              <Icon
                className="delete-icon"
                onClick={this.handleMassDelete}
              >
                delete
              </Icon>
            }
          </TableCell>
        }
      </Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Table className="library__tableheader">
          <TableHead>
            <TableRow className="table__headeraction">
              <TableCell colSpan="12" className="table__headeraction-cell">
                <Button color="default" className="btn-terciery btn__btcirc btn__btcirc-white" id="listViewReport" onClick={this.handleDownloadListViewAsCsvClick}>
                  {this.state.listViewCsvIsLoading && <CircularProgress size={20} />}
                  <Icon>file_download</Icon><span><Translate value="page.library.list.exportToCsv" /></span>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow className="table__head">
              <TableCell className="library__actionscell"><Translate value="page.library.list.play" /></TableCell>
              <TableCell className="library__mediaidcell"><Translate value="page.library.list.mediaId" /></TableCell>
              <TableCell className="library__terminalcell"><Translate value="page.library.list.terminal" /></TableCell>
              <TableCell className="library__groupscell"><Translate value="page.library.list.groups" /></TableCell>
              <TableCell className="library__driveridcell"><Translate value="page.library.list.driverId" /></TableCell>
              <TableCell className="library__drivernamecell"><Translate value="page.library.list.driverName" /></TableCell>
              <TableCell className="library__statuscell"><Translate value="page.library.list.status" /></TableCell>
              <TableCell className="library__vehiclecell"><Translate value="page.library.list.vehicle" /></TableCell>
              <TableCell className="library__eventcell"><Translate value="page.library.list.event" /></TableCell>
              <TableCell className="library__datetimecell"><Translate value="page.library.list.date" /></TableCell>
              <TableCell className="library__starttimecell"><Translate value="page.library.list.startTime" /></TableCell>
              <TableCell className="library__durationcell"><Translate value="page.library.list.duration" /></TableCell>
              {this.renderMassDelete()}
            </TableRow>
          </TableHead>
        </Table>
        <Table className="library__tablerows">
          <TableBody>
            {this.props.medias.map(this.renderLine)}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}
