export const VIDEO_WITH_DASHBOARD_REQUEST = 'VIDEO_WITH_DASHBOARD/REQUEST';
export const VIDEO_WITH_DASHBOARD_SUCCESS = 'VIDEO_WITH_DASHBOARD/SUCCESS';
export const VIDEO_WITH_DASHBOARD_FAILURE = 'VIDEO_WITH_DASHBOARD/FAILURE';

export const VIDEO_DASHBOARD_DOWNLOAD_REQUEST = 'VIDEO_DASHBOARD_DOWNLOAD/REQUEST';
export const VIDEO_DASHBOARD_DOWNLOAD_SUCCESS = 'VIDEO_DASHBOARD_DOWNLOAD/SUCCESS';
export const VIDEO_DASHBOARD_DOWNLOAD_FAILURE = 'VIDEO_DASHBOARD_DOWNLOAD/FAILURE';

export const DOWNLOAD_VIDEO_REQUEST = 'DOWNLOAD_VIDEO/REQUEST';
export const DOWNLOAD_VIDEO_SUCCESS = 'DOWNLOAD_VIDEO/SUCCESS';
export const DOWNLOAD_VIDEO_FAILURE = 'DOWNLOAD_VIDEO/FAILURE';
export const DOWNLOAD_VIDEO_CLEAR_URL = 'DOWNLOAD_VIDEO/CLEAR_URL';
