import PinMarker from '../../images/pin.png';
import ArrowMarker from '../../images/bc.png';
import DotMarker from '../../images/bc-circle.png';
import PastMarker from '../../images/bc-orange.png';
import PastDotMarker from '../../images/bc-orange-circle.png';

class Marker {
  constructor(map, markers, options = {}) {
    this.map = map;
    this.markers = markers;
    this.ALKMaps = window.ALKMaps;
    this.options = options;
  }

  create() {
    if (!this.options.lon || !this.options.lat) {
      return null;
    }
    this.past = this.options.time > -1 && this.options.playerSecond >= this.options.time;
    if (!this.markers) {
      this.markers = new this.ALKMaps.Layer.VectorMarkers(this.options.layerName || 'Vector Layer');
      this.map.addLayer(this.markers);
    }

    const lonLat = this.createLonLat(this.options.lon, this.options.lat);
    this.geometryPoint = new this.ALKMaps.Geometry.Point(lonLat.lon, lonLat.lat);
    this.createMarker(lonLat);

    return null;
  }

  createLonLat(lon, lat) {
    return new this.ALKMaps.LonLat(lon, lat)
      .transform(new this.ALKMaps.Projection('EPSG:4326'), this.map.getProjectionObject());
  }

  update(currentSecond) {
    if (this.marker) {
      this.past = this.options.time > -1 && currentSecond >= this.options.time;
      let nextMarker;
      if (this.validAngle) {
        nextMarker = (this.past) ? PastMarker : ArrowMarker;
      } else {
        nextMarker = (this.past) ? PastDotMarker : DotMarker;
      }
      this.marker.style.externalGraphic = nextMarker;
    }
  }

  createMarker(point) {
    if (this.options.eventMarker) {
      this.marker = this.markers.createMarker(point, this.options.popup, {
        externalGraphic: PinMarker,
        graphicHeight: 18,
        graphicWidth: 16,
        graphicYOffset: -18
      });
      this.centerMap();
    } else if (this.options.angle != null && (this.options.angle !== 510 && this.options.angle !== 690)) {
      this.validAngle = true;
      this.marker = this.markers.createMarker(point, this.options.popup, {
        externalGraphic: this.past ? PastMarker : ArrowMarker,
        graphicHeight: 10,
        graphicWidth: 12,
        rotation: this.options.angle
      });
    } else {
      this.validAngle = false;
      this.marker = this.markers.createMarker(point, this.options.popup, {
        externalGraphic: this.past ? PastDotMarker : DotMarker,
        graphicHeight: 12,
        graphicWidth: 12
      });
    }
    const eventSelect = new ALKMaps.Control.FeatureEvent([this.markers], {
      featureAttributesFilter: [{property: 'isMarker', value: true}]
    });
    this.map.addControls([eventSelect]);
    eventSelect.activate();
    this.marker.style.graphicTitle = this.options.hoverText && this.options.hoverText;
  }

  centerMap() {
    window.setTimeout(() => {
      const lonLat = this.createLonLat(this.options.lon, this.options.lat);
      this.map.setCenter(lonLat, 16);
    }, 300);
  }

  destroy() {
    if (this.markers) {
      this.map.removeLayer(this.markers);
      this.markers.destroy();
    }
  }
}


export default Marker;
