import BaseService from '../../middleware/BaseService';

class FlagVideoService extends BaseService {
  async addFlag(mediaId) {
    return await this.post({}, `/medias/${mediaId}/flag`);
  }
  async removeFlag(mediaId) {
    return await this.remove(null, `/medias/${mediaId}/flag`);
  }
}

export default new FlagVideoService();
