import {
  FLAG_POST_REQUEST,
  FLAG_POST_SUCCESS,
  FLAG_POST_FAIL
} from './FlagVideoActionTypes.js';
import FlagVideoService from './service/FlagVideoService';

export const flagVideo = (mediaId, flag) => async dispatch => {
  try {
    dispatch({type: FLAG_POST_REQUEST});
    let response;
    if (flag === true) {
      response = await FlagVideoService.addFlag(mediaId);
    } else {
      response = await FlagVideoService.removeFlag(mediaId);
    }
    dispatch({type: FLAG_POST_SUCCESS, payload: {response, mediaId, flag}});
  } catch (ex) {
    dispatch({type: FLAG_POST_FAIL, ex});
  }
};

export default {flagVideo};
