import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import NotificationActionType from './NotificationActionType';

const initialState = {
  pulling: false,
  list: [],
  reading: false,
  loadingPrevious: false,
  hasMore: true,
  deleting: false,
  open: false
};

function addNotifications(toAddNotifications, existentNotifications, addFunction) {
  if (isEmpty(toAddNotifications)) {
    return existentNotifications;
  }

  const allNotifications = addFunction(toAddNotifications, existentNotifications);
  return uniqBy(allNotifications, notification => notification.id);
}

function prependNotifications(newNotifications, existentNotifications) {
  return addNotifications(newNotifications, existentNotifications, () => {
    return newNotifications.concat(existentNotifications);
  });
}

function appendNotifications(previousNotifications, existentNotifications) {
  return addNotifications(previousNotifications, existentNotifications, () => {
    return existentNotifications.concat(previousNotifications);
  });
}

export default function(state = initialState, action) {
  switch (action.type) {
    case NotificationActionType.PULL_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        pulling: false
      };

    case NotificationActionType.PULL_NOTIFICATIONS_SUCCESS: {
      const newNotifications = action.payload || [];

      return {
        ...state,
        pulling: false,
        list: prependNotifications(newNotifications, state.list)
      };
    }

    case NotificationActionType.PULL_NOTIFICATIONS_FAIL:
      return {
        ...state,
        pulling: false
      };

    case NotificationActionType.READ_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        reading: true
      };

    case NotificationActionType.READ_NOTIFICATIONS_SUCCESS: {
      const readNotificationIds = get(action, 'payload', []).map(notification => notification.id);

      const notifications = state.list.map(notification => {
        const readRequested = readNotificationIds.includes(notification.id);

        if (readRequested) {
          return {
            ...notification,
            readRequested: true
          };
        }

        return notification;
      });

      return {
        ...state,
        reading: false,
        list: notifications
      };
    }

    case NotificationActionType.READ_NOTIFICATIONS_FAIL:
      return {
        ...state,
        reading: false
      };

    case NotificationActionType.READ_NOTIFICATIONS_COMMIT: {
      const notifications = state.list.map(({readRequested, ...notification}) => {
        return {
          ...notification,
          status: (readRequested ? 'read' : notification.status)
        };
      });

      return {
        ...state,
        list: notifications
      };
    }

    case NotificationActionType.LOAD_PREVIOUS_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        loadingPrevious: true
      };
    }

    case NotificationActionType.LOAD_PREVIOUS_NOTIFICATIONS_SUCCESS: {
      const allNotifications = appendNotifications(action.payload, state.list);
      const hasMore = allNotifications.length !== state.list.length;

      return {
        ...state,
        loadingPrevious: false,
        hasMore,
        list: allNotifications
      };
    }

    case NotificationActionType.DELETE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        deleting: true
      };

    case NotificationActionType.DELETE_NOTIFICATIONS_SUCCESS: {
      const deletedNotificationIds = get(action, 'payload', []).map(notification => notification.id);

      const notifications = state.list.filter(notification => !deletedNotificationIds.includes(notification.id));

      return {
        ...state,
        deleting: false,
        list: notifications
      };
    }

    case NotificationActionType.DELETE_NOTIFICATIONS_FAIL:
      return {
        ...state,
        deleting: false
      };

    case NotificationActionType.NOTIFICATIONS_PANEL_OPEN:
      return {
        ...state,
        open: true
      };

    case NotificationActionType.NOTIFICATIONS_PANEL_CLOSE:
      return {
        ...state,
        open: false
      };

    case NotificationActionType.RESET:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
