import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import withNotificationSetup from './withNotificationSetup';
import {getDashboardDownloadShortDescripton} from '../util/NotificationUtil';

export default function withDashboardDownloadNotification(setup = {}) {
  return withNotificationSetup(merge({
    extraPropTypes: {
      data: PropTypes.shape({
        mediaId: PropTypes.any.isRequired,
        clipId: PropTypes.any.isRequired,
        machineType: PropTypes.any,
        channel: PropTypes.any.isRequired,
        vehicleId: PropTypes.any,
        driverName: PropTypes.string,
        systemMeasure: PropTypes.string
      }).isRequired
    },
    shortDescription: ({data}) => getDashboardDownloadShortDescripton(data)
  }, setup));
}
