/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class BaseNotification extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['unread', 'read']).isRequired,
    date: PropTypes.number.isRequired,
    icon: PropTypes.string,
    variant: PropTypes.oneOfType([
      PropTypes.oneOf(['default', 'primary', 'success', 'error', 'warning']),
      PropTypes.string
    ]),
    title: PropTypes.string,
    description: PropTypes.string,
    shortDescription: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.func
    ]),
    closeOnClick: PropTypes.bool,
    closeNotificationsPanel: PropTypes.func,
    deleteNotification: PropTypes.func,
    onClick: PropTypes.func
  }

  static defaultProps = {
    icon: 'notifications',
    variant: 'default',
    title: 'notification.default.title',
    description: 'notification.default.description',
    shortDescription: null,
    closeOnClick: true,
    closeNotificationsPanel: () => {},
    deleteNotification: () => {},
    onClick: () => {}
  }

  state = {
    menuAnchorEl: null,
    loading: false
  }

  handleClick = async () => {
    this.setState({
      loading: true
    });

    await this.props.onClick();

    if (this.props.closeOnClick) {
      this.props.closeNotificationsPanel();
    }

    this.setState({
      loading: false
    });
  }

  handleOpenMenuClick = event => {
    this.setState({
      menuAnchorEl: event.currentTarget
    });
  }

  handleCloseMenu = () => {
    this.setState({
      menuAnchorEl: null
    });
  }

  handleDeleteClick = () => {
    this.props.deleteNotification({
      id: this.props.id
    });

    this.handleCloseMenu();
  }

  renderIcon() {
    const {variant, icon} = this.props;

    return (
      <Avatar className={`notification__avatar-${variant}`}>
        <Icon>{icon}</Icon>
      </Avatar>
    );
  }

  renderTitle() {
    const {title} = this.props;
    const {loading} = this.state;

    return (
      <>
        {I18n.t(title, title)}
        {loading && (
          <CircularProgress size={15} className="notification__internal-loading" />
        )}
      </>
    );
  }

  renderDescription() {
    const {description} = this.props;
    return I18n.t(description, description);
  }

  renderShortDescription() {
    return this.props.shortDescription;
  }

  renderTime() {
    const momentDate = moment(this.props.date);

    return (
      <span title={momentDate.format(I18n.t('configuration.dateTimeFormat'))}>
        {momentDate.fromNow(true)}
      </span>
    );
  }

  renderShortDescriptionAndTime() {
    let shortDescription = this.renderShortDescription();

    if (!shortDescription) {
      return this.renderTime();
    }

    if (typeof shortDescription === 'function') {
      const {props} = this;
      shortDescription = shortDescription({
        ...props,
        data: props.data || {}
      });
    }

    if (typeof shortDescription === 'string' && shortDescription.trim().length > 0) {
      shortDescription = shortDescription.trim().split(' | ');
    } else if (Array.isArray(shortDescription) && shortDescription.length > 0) {
      shortDescription = shortDescription.filter(part => !!part);
    } else {
      shortDescription = [];
    }

    return (
      <>
        {shortDescription.map(item => (
          <React.Fragment key={item}>
            <span>{item}</span>
            <span> | </span>
          </React.Fragment>
        ))}
        {this.renderTime()}
      </>
    );
  }

  renderDescriptions() {
    return (
      <>
        <Typography
          component="span"
          variant="caption"
          display="block"
          className="notification__short-description"
        >
          {this.renderShortDescriptionAndTime()}
        </Typography>

        <Typography
          component="span"
          variant="body1"
          display="block"
          className="notification__description"
        >
          {this.renderDescription()}
        </Typography>
      </>
    );
  }

  renderContent() {
    return (
      <ListItemText
        classes={{
          root: 'notification__wrapper',
          primary: 'notification__title'
        }}
        primary={this.renderTitle()}
        secondary={this.renderDescriptions()}
      />
    );
  }

  renderOptionsMenu() {
    const {id} = this.props;
    const {menuAnchorEl} = this.state;

    return (
      <ListItemSecondaryAction className="notification__actions">
        <IconButton
          className="notification__options-menu"
          aria-owns={`notification-${id}-options-menu`}
          aria-haspopup="true"
          onClick={this.handleOpenMenuClick}
        >
          <Icon>more_vert</Icon>
        </IconButton>

        <Menu
          id={`notification-${id}-options-menu`}
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          keepMounted
          onClose={this.handleCloseMenu}
        >
          <MenuItem onClick={this.handleDeleteClick}>
            <ListItemIcon classes={{root: 'notification__icon-delete'}}>
              <Icon>delete</Icon>
            </ListItemIcon>

            {I18n.t('page.notifications.button.delete')}
          </MenuItem>
        </Menu>
      </ListItemSecondaryAction>
    );
  }

  render() {
    return (
      <ListItem
        button
        divider
        alignItems="flex-start"
        className={`notification__status-${this.props.status}`}
        onClick={this.handleClick}
      >
        {this.renderIcon()}
        {this.renderContent()}

        {this.renderOptionsMenu()}
      </ListItem>
    );
  }
}
