import React from 'react';
import {Line} from 'react-chartjs-2';
import PropTypes from 'prop-types';


const defaultOptions = {
  tooltips: {
    mode: 'index',
    axis: 'y'
  },
  scales: {
    yAxes: [
      {
        id: 'y-axis-1',
        display: true,
        position: 'left'
      }
    ]
  }
};


export default class BarLineGraph extends React.PureComponent {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.shape({}),
    dataset: PropTypes.shape({}),
    datasets: PropTypes.arrayOf(PropTypes.shape({})),
    height: PropTypes.number,
    width: PropTypes.number
  }

  static defaultProps = {
    datasets: null,
    dataset: {},
    options: {},
    height: 240,
    width: 680
  }

  render() {
    const {
      dataset,
      labels,
      options
    } = this.props;

    let {datasets} = this.props;

    if (!dataset && !datasets) {
      return null;
    } else if (!datasets) {
      datasets = [
        {
          ...dataset,
          yAxisID: 'y-axis-1'
        }
      ];
    }

    const data = {
      datasets,
      labels
    };
    return (
      <Line
        data={data}
        options={{...defaultOptions, ...options}}
        height={this.props.height}
        width={this.props.width}
      />
    );
  }
}
