import DownloadVideoService from './service/DownloadVideoService';
import DownloadVideoDashboardService from './service/DownloadVideoDashboardService';

import {
  VIDEO_WITH_DASHBOARD_REQUEST,
  VIDEO_WITH_DASHBOARD_SUCCESS,
  VIDEO_WITH_DASHBOARD_FAILURE,
  VIDEO_DASHBOARD_DOWNLOAD_REQUEST,
  VIDEO_DASHBOARD_DOWNLOAD_SUCCESS,
  VIDEO_DASHBOARD_DOWNLOAD_FAILURE,
  DOWNLOAD_VIDEO_REQUEST,
  DOWNLOAD_VIDEO_SUCCESS,
  DOWNLOAD_VIDEO_FAILURE,
  DOWNLOAD_VIDEO_CLEAR_URL
} from './DownloadVideoActionsType';

export const requestVideoWithDashboard = (mediaId, clipId) => async dispatch => {
  dispatch({type: VIDEO_WITH_DASHBOARD_REQUEST});
  try {
    const url = await DownloadVideoDashboardService.requestVideoDasboardDownload(mediaId, clipId);

    if (!url.url) {
      dispatch({type: VIDEO_WITH_DASHBOARD_SUCCESS});
    } else {
      dispatch({type: DOWNLOAD_VIDEO_SUCCESS, payload: url});
    }
  } catch (e) {
    console.error('Unable to request video with dashboard', e);
    dispatch({type: VIDEO_WITH_DASHBOARD_FAILURE});
  }
};

export const downloadVideoDashboard = (mediaId, clipId, systemMeasure) => async dispatch => {
  dispatch({
    type: VIDEO_DASHBOARD_DOWNLOAD_REQUEST
  });

  try {
    await DownloadVideoDashboardService.downloadVideoDashboard(mediaId, clipId, systemMeasure);

    dispatch({
      type: VIDEO_DASHBOARD_DOWNLOAD_SUCCESS
    });
  } catch (e) {
    console.error('Unable to download video dashboard', e);

    dispatch({
      type: VIDEO_DASHBOARD_DOWNLOAD_FAILURE
    });
  }
};

export const getUrlVideo = clip => async dispatch => {
  dispatch({type: DOWNLOAD_VIDEO_REQUEST});

  try {
    const urlVideo = await DownloadVideoService.getUrlVideo(clip);

    dispatch({type: DOWNLOAD_VIDEO_SUCCESS, payload: urlVideo});
  } catch (e) {
    console.error('Unable to download video', e);

    dispatch({type: DOWNLOAD_VIDEO_FAILURE});
  }
};

export const clearUrl = () => async dispatch => dispatch({type: DOWNLOAD_VIDEO_CLEAR_URL});
