/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClearNotificationsDialog from './ClearNotificationsDialog';
import NotificationContainer from './NotificationContainer';

export default class NotificationList extends React.Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string,
      status: PropTypes.oneOf(['unread', 'read']).isRequired,
      date: PropTypes.number.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      shortDescription: PropTypes.any,
      data: PropTypes.any,
      userId: PropTypes.any,
      customerId: PropTypes.any
    })).isRequired,
    onScroll: PropTypes.func,
    children: PropTypes.node
  }

  static defaultProps = {
    onScroll: () => {},
    children: undefined
  }

  state = {
    menuAnchorEl: null,
    clearConfirmationOpen: false
  }

  handleOpenOptionsMenu = event => {
    this.setState({
      menuAnchorEl: event.currentTarget
    });
  }

  handleCloseOptionsMenu = () => {
    this.setState({
      menuAnchorEl: null
    });
  }

  handleShowClearConfirmation = () => {
    this.setState({
      menuAnchorEl: null,
      clearConfirmationOpen: true
    });
  }

  handleCloseClearConfirmation = () => {
    this.setState({
      clearConfirmationOpen: false
    });
  }

  hasNotifications() {
    const {notifications} = this.props;
    return notifications && notifications.length > 0;
  }

  renderEmptyMessage() {
    if (this.hasNotifications()) {
      return null;
    }

    return (
      <p className="notification__empty">
        {I18n.t('page.notifications.empty')}
      </p>
    );
  }

  renderNotifications() {
    if (!this.hasNotifications()) {
      return null;
    }

    return (
      <>
        {this.props.notifications.map(notification => (
          <NotificationContainer
            key={notification.id}
            notification={notification}
          />
        ))}
      </>
    );
  }

  renderOptionsMenu() {
    const {menuAnchorEl, clearConfirmationOpen} = this.state;

    return (
      <>
        <IconButton
          className="notification__options-menu"
          aria-owns="notification-list-options-menu"
          aria-haspopup="true"
          onClick={this.handleOpenOptionsMenu}
        >
          <Icon>more_vert</Icon>
        </IconButton>

        <Menu
          id="notification-list-options-menu"
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          keepMounted
          onClose={this.handleCloseOptionsMenu}
        >
          <MenuItem onClick={this.handleShowClearConfirmation}>
            <ListItemIcon classes={{root: 'notification__icon-delete'}}>
              <Icon>delete</Icon>
            </ListItemIcon>
            {I18n.t('page.notifications.button.clear')}
          </MenuItem>
        </Menu>

        <ClearNotificationsDialog
          open={clearConfirmationOpen}
          onClose={this.handleCloseClearConfirmation}
          onClearDone={this.handleCloseClearConfirmation}
        />
      </>
    );
  }

  render() {
    return (
      <div
        className="notification__list-container"
        role="presentation"
        onScroll={this.props.onScroll}
      >
        <List
          className="notification__list"
          component="nav"
          aria-labelledby="notification-list-subheader"
          subheader={
            <ListSubheader
              id="notification-list-subheader"
              component="div"
              className="list-subheader"
            >
              {I18n.t('page.notifications.header')}
              {this.renderOptionsMenu()}
            </ListSubheader>
          }
        >
          {this.renderEmptyMessage()}
          {this.renderNotifications()}

          {this.props.children}
        </List>
      </div>
    );
  }
}
