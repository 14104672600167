import React from 'react';
import {I18n} from 'react-redux-i18n';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import {get, camelCase} from 'lodash';
import PointChip from './PointChip';
import CustomReview from './ReviewCustom';

const getReviewPoints = category => get(category, 'reviewOptions', []);

const createReviewCheckboxId = reviewReason => {
  const categoryName = camelCase(reviewReason.categoryName);
  const reviewName = camelCase(reviewReason.name);
  return `${categoryName}_${reviewName}`;
};

const ReviewForm = ({
  review: {
    mainCategory,
    otherCategories,
    completed,
    edited
  },
  handleCustomReviewChange,
  handleCheckReviewOption,
  handleReviewSubmit,
  openCloseReviewSubmittedChip,
  handleCheckReviewCompleted,
  cancelEditReview,
  showScore,
  canSubmit,
  ...props
}) => {
  const renderMainCategory = reviewReason => {
    if (reviewReason.custom) {
      return (
        <ListItem key="custom" className="mediareview__inputtext">
          <CustomReview
            id={camelCase(reviewReason.categoryName)}
            onChange={handleCustomReviewChange}
            value={reviewReason.name}
            points={reviewReason.points}
            scoreEnable={showScore}
          />
        </ListItem>
      );
    }
    return (
      <ListItem key={reviewReason.name} className="mediareview__listitem mediareview__reverse">
        <FormControlLabel
          label={I18n.t(reviewReason.nameId, reviewReason.name)}
          control={
            <Checkbox
              id={createReviewCheckboxId(reviewReason)}
              className="reviewdesktop__checkbox"
              onClick={() => handleCheckReviewOption(reviewReason.categoryName, reviewReason.name)}
              checked={reviewReason.marked}
            />
          }
        />
      </ListItem>
    );
  };
  return (
    <Dialog
      fullScreen
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
      open={props.open}
      onClose={props.onClose}
      className="mediareview"
    >
      <List>
        <ListItem>
          <h2>
            {I18n.t(mainCategory.categoryNameId, mainCategory.categoryName)} <PointChip points={mainCategory.points} display={showScore} />
          </h2>
        </ListItem>
        {getReviewPoints(mainCategory).map(renderMainCategory)}
        {otherCategories && otherCategories.map(category => (
          <React.Fragment key={category.categoryNameId}>
            <ListItem>
              <h2 className="mediareview__titlespace">{I18n.t(category.categoryNameId, category.categoryName)}</h2>
            </ListItem>
            {getReviewPoints(category).map(reviewReason => (
              <ListItem key={reviewReason.name} className="mediareview__listitem mediareview__listitem-align">
                {I18n.t(reviewReason.nameId, reviewReason.name)}
                <div className="mediareview__listitem-labelcheck">
                  <PointChip points={reviewReason.points} display={showScore} />
                  <Checkbox
                    id={createReviewCheckboxId(reviewReason)}
                    onClick={() => handleCheckReviewOption(reviewReason.categoryName, reviewReason.name)}
                    checked={reviewReason.marked}
                  />
                </div>
              </ListItem>
            ))}
          </React.Fragment>
        ))}

        <ListItem>
          <BottomNavigation className="mediareview__action">
            <label>
              {I18n.t('page.playerPage.reviewForm.reviewComplete')}
              <Checkbox
                id="reviewCompleted"
                onClick={handleCheckReviewCompleted}
                checked={completed}
              />
            </label>
            <Button
              id="cancelEditReviewButton"
              variant="contained"
              color="default"
              className="btn btn-secondary"
              onClick={cancelEditReview}
            >
              {I18n.t('page.playerPage.reviewForm.cancel')}
            </Button>
            <Button
              id="submitReviewButton"
              variant="contained"
              color="default"
              className="btn btn-primary"
              disabled={!canSubmit || !edited}
              title={!canSubmit && I18n.t('defaults.noPermissionFunctionality')}
              onClick={handleReviewSubmit}
            >
              {I18n.t('page.playerPage.coachingForm.submit')}
            </Button>
          </BottomNavigation>
        </ListItem>
      </List>
    </Dialog>
  );
};

ReviewForm.defaultProps = {
  review: {
    mainCategory: {},
    otherCategories: [],
    completed: false,
    edited: false
  }
};

export default ReviewForm;
