import React from 'react';
import {Bar} from 'react-chartjs-2';
import PropTypes from 'prop-types';


const defaultOptions = {
  tooltips: {
    mode: 'index',
    axis: 'y'
  },
  scales: {
    yAxes: [
      {
        id: 'y-axis-1',
        display: true,
        position: 'left'
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'y-axis-2',
        gridLines: {
          display: false
        }
      }
    ]
  }
};


export default class BarLineGraph extends React.PureComponent {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.shape({}),
    barDataset: PropTypes.shape({}),
    lineDataset: PropTypes.shape({}),
    height: PropTypes.number,
    width: PropTypes.number
  }

  static defaultProps = {
    barDataset: {},
    lineDataset: {},
    options: {},
    height: 240,
    width: 680
  }

  render() {
    const {
      barDataset,
      labels,
      lineDataset,
      options = {}
    } = this.props;

    if (!barDataset && !lineDataset) {
      return null;
    }


    const data = {
      datasets: [
        {
          ...barDataset,
          yAxisID: 'y-axis-1'
        },
        {
          ...lineDataset,
          type: 'line',
          yAxisID: 'y-axis-2'
        }
      ],
      labels
    };
    return (
      <Bar
        data={data}
        options={{...defaultOptions, ...options}}
        height={this.props.height}
        width={this.props.width}
      />
    );
  }
}
