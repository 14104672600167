import React from 'react';
import NotificationComponentFactory from './NotificationComponentFactory';

export default class NotificationContainer extends React.PureComponent {
  render() {
    const {notification} = this.props;

    if (!notification) {
      return null;
    }

    const Notification = NotificationComponentFactory.get(notification);

    return (
      <Notification {...this.props} {...notification} />
    );
  }
}
