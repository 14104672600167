import BaseNotification from './BaseNotification';
import {
  DashboardDownloadRequestNotification,
  DashboardDownloadSuccessNotification,
  DashboardDownloadErrorNotification
} from './types/DashboardDownloadNotifications';

const notificationComponentPerType = {
  videoDownloadRequested: DashboardDownloadRequestNotification,
  videoDownloadProcessing: DashboardDownloadRequestNotification, //Not sure why there are two types of requested notifications
  videoDownloadProcessed: DashboardDownloadSuccessNotification,
  videoErrorProcessing: DashboardDownloadErrorNotification
};

export default class NotificationComponentFactory {
  static get(notification) {
    if (!notification || !notification.type) {
      return BaseNotification;
    }

    const Component = notificationComponentPerType[notification.type];

    if (!Component) {
      return BaseNotification;
    }

    return Component;
  }
}
