import moment from 'moment-timezone';
import BaseService from '../middleware/BaseService';

const DASHBOARD_ENDPOINT = '/report/dashboard/summary';

class DashboardService extends BaseService {
  prefix = DASHBOARD_ENDPOINT;
  async getSummaryInformation(filter) {
    const timezone = moment.tz.guess();
    const params = {
      timezone,
      fromDate: filter.startTime,
      toDate: filter.endTime,
      not_oer: filter.oerList,
      systemMeasure: filter.systemMeasure
    };

    if (filter.terminal) params.terminal = filter.terminal;
    if (filter.trigger) params.trigger = filter.trigger;

    const response = await this.search(params);
    return response;
  }
}

export default new DashboardService();
