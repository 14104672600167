import React from 'react';
import {connect} from 'react-redux';
import BaseNotification from './BaseNotification';
import {closeNotificationsPanel, deleteNotification} from '../NotificationActions';

export default function withNotificationSetup(setup = {}) {
  const {
    extraPropTypes,
    extraDefaultProps,
    mapDispatchToProps,
    onClick,
    ...props
  } = setup;

  const Notification = class extends React.PureComponent {
    static propTypes = {
      ...BaseNotification.propTypes,
      ...setup.extraPropTypes
    }

    static defaultProps = {
      ...BaseNotification.defaultProps,
      ...setup.extraDefaultProps
    }

    handleClick = () => {
      if (setup.onClick) {
        return setup.onClick.call(this, this.props);
      }

      return null;
    }

    render() {
      return (
        <BaseNotification
          {...this.props}
          {...props}
          onClick={this.handleClick}
        />
      );
    }
  };

  return connect(setup.mapStateToProps, {
    closeNotificationsPanel,
    deleteNotification,
    ...mapDispatchToProps
  })(Notification);
}
