import React from 'react';

function WrapComponent(Child) {
  return class AlkMapScriptLoader extends React.Component {
        state = {
          loaded: !!window.ALKMaps,
          error: false
        }

        componentDidMount() {
          if (!window.ALKMaps) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://maps.alk.com/api/1.2/alkmaps.js';
            script.onload = () => this.setState({loaded: true});
            script.onerror = () => this.setState({error: true});
            document.head.appendChild(script);
          }
        }

        render() {
          const {loaded, error} = this.state;

          if (loaded && window.ALKMaps) {
            return <Child {...this.props} />;
          } else if (error) {
            // TODO: Real error page
            return 'Not Found';
          }
          return null;
        }
  };
}

export default WrapComponent;
