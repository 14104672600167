import React from 'react';
import Icon from '@material-ui/core/Icon';
import {I18n} from 'react-redux-i18n';

const defaultValues = {
  TAG_REVIEW: {
    style: 'local_offer',
    text: 'tagReviewed'
  },
  REVIEW_COMPLETED: {
    style: 'assignment_turned_in',
    text: 'reviewCompleted'
  },
  COACH_COMPLETED: {
    style: 'supervisor_account',
    text: 'coachCompleted'
  },
  REVIEWED: {
    style: 'check_circle',
    text: 'reviewed'
  }
};

const checkStyle = props => {
  if (props.reviewCompleted) {
    return defaultValues.REVIEW_COMPLETED;
  } else if (props.coachCompleted) {
    return defaultValues.COACH_COMPLETED;
  } else if (props.reviewed) {
    return defaultValues.REVIEWED;
  } else if (props.tagReviewed) {
    return defaultValues.TAG_REVIEW;
  }
  return null;
};

class ReviewStatus extends React.PureComponent {
  render() {
    const statusStyle = checkStyle(this.props);
    const {isMobile, wrapper: Wrapper = 'span'} = this.props;
    return (
      <Wrapper className="reviewitem">
        {!isMobile && <label>{I18n.t('page.playerPage.reviewDetails.status')}</label>}
        {statusStyle && statusStyle !== defaultValues.TAG_REVIEW && <span>{I18n.t(`page.playerPage.reviewDetails.${statusStyle.text}`)} <Icon className="videoactions__reviewed_complete reviewdesktop-icon">{statusStyle.style}</Icon></span>}
        {statusStyle && statusStyle === defaultValues.TAG_REVIEW && <span>{I18n.t(`page.playerPage.reviewDetails.${statusStyle.text}`)} <Icon className="videoactions__tagged-active reviewdesktop-icon">{statusStyle.style}</Icon></span>}
      </Wrapper>
    );
  }
}

export default ReviewStatus;
