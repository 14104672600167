import React from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

class ProgressSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show
    };
  }

  // toggle(bool, _timer) {
  // setTimeout(() => this.setState({ isOpen: bool }), (_timer ? _timer : 0));
  // }

  // componentDidMount() {
  // setTimeout(() => this.setState({ isLoading: false }), 800);
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.state.show) {
      this.setState({show: nextProps.show});
    }
  }

  render() {
    if (this.state.show) {
      return (
        <div className="loading">
          <CircularProgress className="loading__cirgularprogress" size={50} />
        </div>
      );
    }

    return null;
  }
}

ProgressSpinner.defaultProps = {
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ProgressSpinner);
