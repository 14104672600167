/* eslint-disable import/prefer-default-export */
import notificationService from './NotificationService';
import NotificationActionType from './NotificationActionType';

export const pullNotifications = () => async dispatch => {
  try {
    dispatch({
      type: NotificationActionType.PULL_NOTIFICATIONS_REQUEST
    });

    const notifications = await notificationService.pullNotifications();

    dispatch({
      type: NotificationActionType.PULL_NOTIFICATIONS_SUCCESS,
      payload: notifications
    });
  } catch (e) {
    console.error('Unable to pull the notifications', e);

    dispatch({
      type: NotificationActionType.PULL_NOTIFICATIONS_FAIL
    });
  }
};

export const readNotifications = notifications => async dispatch => {
  try {
    dispatch({
      type: NotificationActionType.READ_NOTIFICATIONS_REQUEST
    });

    await notificationService.readNotifications(notifications);

    dispatch({
      type: NotificationActionType.READ_NOTIFICATIONS_SUCCESS,
      payload: notifications
    });
  } catch (e) {
    console.error('Unable to read the notifications', e);

    dispatch({
      type: NotificationActionType.READ_NOTIFICATIONS_FAIL,
      payload: notifications
    });
  }
};

export const commitReadNotifications = () => {
  return {
    type: NotificationActionType.READ_NOTIFICATIONS_COMMIT
  };
};

export const loadPreviousNotifications = notifications => async dispatch => {
  try {
    dispatch({
      type: NotificationActionType.LOAD_PREVIOUS_NOTIFICATIONS_REQUEST
    });

    const previousNotifications = await notificationService.loadPreviousNotifications(notifications);

    dispatch({
      type: NotificationActionType.LOAD_PREVIOUS_NOTIFICATIONS_SUCCESS,
      payload: previousNotifications
    });
  } catch (e) {
    console.error('Unable to load previous notifications', e);

    dispatch({
      type: NotificationActionType.LOAD_PREVIOUS_NOTIFICATIONS_FAIL
    });
  }
};

export const deleteNotification = notification => async dispatch => {
  try {
    dispatch({
      type: NotificationActionType.DELETE_NOTIFICATIONS_REQUEST
    });

    await notificationService.deleteNotification(notification);

    dispatch({
      type: NotificationActionType.DELETE_NOTIFICATIONS_SUCCESS,
      payload: [notification]
    });
  } catch (e) {
    console.error('Unable to delete the notification', e);

    dispatch({
      type: NotificationActionType.DELETE_NOTIFICATIONS_FAIL
    });
  }
};

export const deleteNotifications = notifications => async dispatch => {
  try {
    dispatch({
      type: NotificationActionType.DELETE_NOTIFICATIONS_REQUEST
    });

    await notificationService.deleteNotifications(notifications);

    dispatch({
      type: NotificationActionType.DELETE_NOTIFICATIONS_SUCCESS,
      payload: notifications
    });
  } catch (e) {
    console.error('Unable to clear the notifications', e);

    dispatch({
      type: NotificationActionType.DELETE_NOTIFICATIONS_FAIL
    });
  }
};

export const openNotificationsPanel = () => {
  return {
    type: NotificationActionType.NOTIFICATIONS_PANEL_OPEN
  };
};

export const closeNotificationsPanel = () => {
  return {
    type: NotificationActionType.NOTIFICATIONS_PANEL_CLOSE
  };
};

export const reset = () => {
  return {
    type: NotificationActionType.RESET
  };
};
