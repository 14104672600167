import DashboardService from './DashboardService';
import {
  REQUEST_SUMMARY_INFORMATION,
  REQUEST_SUMMARY_INFORMATION_SUCCESS,
  REQUEST_SUMMARY_INFORMATION_FAILURE
} from './DashboardActionTypes';

export const getDashboardSummary = filter => async dispatch => {
  try {
    const oerList = Object.keys(filter.oerList).filter(a => !filter.oerList[a])
    dispatch({type: REQUEST_SUMMARY_INFORMATION});
    const data = await DashboardService.getSummaryInformation({...filter, oerList});
    return dispatch({type: REQUEST_SUMMARY_INFORMATION_SUCCESS, payload: data});
  } catch (e) {
    return dispatch({type: REQUEST_SUMMARY_INFORMATION_FAILURE, error: e});
  }
};
