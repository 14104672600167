import {get} from 'lodash';

import {
  DOWNLOAD_VIDEO_REQUEST,
  DOWNLOAD_VIDEO_SUCCESS,
  DOWNLOAD_VIDEO_FAILURE,
  DOWNLOAD_VIDEO_CLEAR_URL
} from './DownloadVideoActionsType';

const initialState = {
  clipUrl: null,
  isLoadingVideoURL: false
};

export default function downloadVideo(state = initialState, action) {
  const type = get(action, 'type');

  switch (type) {
    case DOWNLOAD_VIDEO_REQUEST:
      return {
        ...state,
        isLoadingVideoURL: true,
        clipUrl: initialState.clipUrl
      };

    case DOWNLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        isLoadingVideoURL: false,
        clipUrl: get(action, 'payload.url', initialState.clipUrl)
      };

    case DOWNLOAD_VIDEO_FAILURE:
      return {
        ...state,
        isLoadingVideoURL: false,
        clipUrl: initialState.clipUrl
      };

    case DOWNLOAD_VIDEO_CLEAR_URL:
      return {...state, ...initialState};

    default:
      return state;
  }
}
