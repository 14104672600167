import LockVideoService from './service/LockVideoService';

export const lockVideo = (mediaId, userId) => async dispatch => {
  return dispatch(LockVideoService.lockVideo(mediaId, userId));
};

export const unLockVideo = (mediaId, userId) => async dispatch => {
  return dispatch(LockVideoService.unLockVideo(mediaId, userId));
};
export default {lockVideo};
