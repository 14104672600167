// @flow

import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {withStyles} from '@material-ui/core/styles';

import {
  applyFilter,
  // clear,
  clearFilter,
  updateChipFilter,
  loadGroups,
  loadOerTypes,
  loadTerminals
} from './Actions/filterActions';

import DesktopFilter from './DesktopFilters';

const styleSheet = () => ({
  flex: {
    flex: 1
  }
});

export const FiltersContainer = props => {
  // TODO: implement MobileFilter
  return !props.isMobile && <DesktopFilter {...props} />;
};

function mapStateToProps(state) {
  const {filters, customer} = state;
  const oerTypeFilter = get(filters, 'selectedFilters.oerTypeFilter');
  const terminalsFilter = get(filters, 'selectedFilters.terminalsFilter');
  const groupsFilter = get(filters, 'selectedFilters.groupsFilter');
  const constrainedFilters = get(filters, 'selectedFilters.constrainedFilters');
  const chipsFilter = get(filters, 'selectedFilters.chipsFilter');
  const mediaId = get(filters, 'selectedFilters.mediaId');
  const startTime = get(filters, 'selectedFilters.startTime');
  const endTime = get(filters, 'selectedFilters.endTime');
  return {
    customer,
    ...filters,
    oerTypeFilter,
    terminalsFilter,
    groupsFilter,
    constrainedFilters,
    chipsFilter,
    mediaId,
    startTime,
    endTime
  };
}

const mapDispatchToProps = dispatch => ({
  applyFilter: filters => dispatch(applyFilter(filters)),
  loadGroups: () => dispatch(loadGroups()),
  loadOerTypes: () => dispatch(loadOerTypes()),
  loadTerminals: () => dispatch(loadTerminals()),
  clearFilter: () => dispatch(clearFilter()),
  // clear: () => dispatch(clear()),
  updateChipFilter: chips => dispatch(updateChipFilter(chips))
});

const stylished = withStyles(styleSheet)(FiltersContainer);
export default connect(mapStateToProps, mapDispatchToProps)(stylished);
