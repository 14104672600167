import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import {I18n} from 'react-redux-i18n';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default ({
  onCancel,
  onConfirm
}) => {
  return (
    <Fragment>
      <DialogTitle id="delete-dialog-title">
        {I18n.t('action.deleteVideo.deleteConfirmation.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          {I18n.t('action.deleteVideo.deleteConfirmation.message')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} id="playerDeleteCancel">
          {I18n.t('defaults.no')}
        </Button>
        <Button onClick={onConfirm} color="primary" id="playerDeleteConfirm">
          {I18n.t('defaults.yes')}
        </Button>
      </DialogActions>
    </Fragment>
  );
};
