/* eslint-disable react/sort-comp */
import React from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {pullNotifications, reset} from './NotificationActions';

const TIME_FOR_POLLING_NOTIFICATIONS = 30000;

class NotificationObserver extends React.PureComponent {
  intervalId = null

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.stop();
    this.props.reset();
  }

  start() {
    if (this.intervalId) {
      return;
    }

    this.handlePullNotifications();
    this.intervalId = setInterval(this.handlePullNotifications, TIME_FOR_POLLING_NOTIFICATIONS);
  }

  stop() {
    if (!this.intervalId) {
      return;
    }

    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  handlePullNotifications = () => {
    if (this.props.pulling) {
      return;
    }

    this.props.pullNotifications();
  }

  render() {
    return null;
  }
}

const withRedux = connect(state => {
  return {
    pulling: get(state, 'notifications.pulling', false)
  };
}, {
  pullNotifications,
  reset
});

export default withRedux(NotificationObserver);
