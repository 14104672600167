/* eslint-disable jsx-a11y/label-has-for */
import {get, map} from 'lodash';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import {I18n} from 'react-redux-i18n';
import ReviewCompletedChip from './ReviewCompletedChip';
import ReviewStatus from './ReviewStatus';
import ReviewForm from './ReviewFormMobile';


class ReviewDetails extends React.Component {
  state = {
    reviewOpen: false
  }

  toggleDialog = () => {
    this.setState({reviewOpen: !this.state.reviewOpen});
  }

  cancelEditReview = () => {
    this.props.cancelEditReview();
    this.toggleDialog();
  }

  renderReview = reviewed => {
    if (reviewed) {
      const {review} = this.props;

      const reviewList = () => {
        if (review && review.score) {
          const reviewSize = review.score.filter(i => i.marked).length;
          if (reviewSize > 0) {
            return (
              <ul className="mediareview__list">
                {map(review.score, (item, index) => item.marked && <li key={index}>{I18n.t(item.nameId, item.name)}</li>)}
              </ul>
            );
          }

          return (<p>{I18n.t('page.playerPage.mediaDetails.reviewedUncategorized')}</p>);
        }
        return null;
      };


      return (
        <List>
          <ListItem>
            <strong className="label">{I18n.t('page.playerPage.mediaDetails.review')}</strong>
          </ListItem>
          <ListItem>
            {reviewList()}
            <Icon onClick={this.toggleDialog}>edit</Icon>
          </ListItem>
          <ListItem>
            <Icon className="mediareview__usericon">person</Icon>
            <ListItemText primary={review.reviewerName} secondary={I18n.t('page.playerPage.mediaDetails.reviewer')} />
          </ListItem>
        </List>
      );
    }

    return (
      <List>
        <ListItem className="media__actioncont">
          <Button
            variant="contained"
            onClick={this.toggleDialog}
            className="btn btn-primary"
          >
            {I18n.t('page.playerPage.mediaDetails.addReview')}
          </Button>
        </ListItem>
      </List>
    );
  }


  render() {
    const {
      media: {
        metadata: {
          driverName1
        },
        tag,
        metadata
      },
      review,
      savedReview,
      coach,
      address,
      handleCustomReviewChange,
      handleCheckReviewOption,
      openCloseReviewSubmittedChip,
      handleReviewSubmit,
      handleCheckReviewCompleted,
      showScore,
      canSubmit
    } = this.props;

    const reviewed = get(metadata, 'review', false);

    return (
      <React.Fragment>
        <List className="mediareview__reviewstatus">
          <ReviewStatus
            tagReviewed={get(tag, 'review.value', false)}
            coachCompleted={get(coach, 'completed', false)}
            reviewCompleted={savedReview.completed}
            reviewed={reviewed}
            isMobile
            wrapper={ListItem}
          />
          {review.showReviewSubmittedChip && <ReviewCompletedChip handleReviewSubmittedChip={openCloseReviewSubmittedChip} />}
          {metadata && driverName1 != null && (
            <ListItem>
              <Icon className="mediareview__usericon">person</Icon>
              <ListItemText primary={driverName1} secondary={I18n.t('page.playerPage.mediaDetails.driver')} />
            </ListItem>
          )}
        </List>
        <Divider />
        {this.renderReview(reviewed)}
        <ReviewForm
          open={this.state.reviewOpen}
          onClose={this.toggleDialog}
          review={review}
          address={address}
          handleCustomReviewChange={handleCustomReviewChange}
          handleCheckReviewOption={handleCheckReviewOption}
          handleReviewSubmit={handleReviewSubmit}
          handleCheckReviewCompleted={handleCheckReviewCompleted}
          cancelEditReview={this.cancelEditReview}
          showScore={showScore}
          canSubmit={canSubmit}
        />
      </React.Fragment>
    );
  }
}

export default ReviewDetails;
