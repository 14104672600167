export const MEDIA_SEARCH_REQUEST = 'MEDIA_SEARCH_REQUEST';
export const MEDIA_SEARCH_SUCCESS = 'MEDIA_SEARCH_SUCCESS';
export const MEDIA_SEARCH_FAIL = 'MEDIA_SEARCH_FAIL';
export const MEDIA_LOADMORE_SUCCESS = 'MEDIA_LOADMORE_SUCCESS';
export const MEDIA_LOADMORE_REQUEST = 'MEDIA_LOADMORE_REQUEST';
export const MEDIA_LOADMORE_FAIL = 'MEDIA_LOADMORE_FAIL';
export const LIBRARY_CLEAR = 'LIBRARY_CLEAR';
export const LIBRARY_CLEAN_SAVE = 'LIBRARY_CLEAN_SAVE';
export const MEDIA_FORCE_SEARCH = 'MEDIA_FORCE_SEARCH';
export const LIBRARY_SAVE = 'LIBRARY/SAVE';
export const UPDATE_MEDIALIST = 'LIBRARY/UPDATE_MEDIALIST';
export const CHANGE_VIEWMODE = 'LIBRARY/CHANGE_VIEWMODE';
export const DOWNLOAD_LIST_VIEW_AS_CSV_REQUEST = 'DOWNLOAD_LIST_VIEW_AS_CSV_REQUEST';
export const DOWNLOAD_LIST_VIEW_AS_CSV_SUCCESS = 'DOWNLOAD_LIST_VIEW_AS_CSV_SUCCESS';
export const CLEAR_DOWNLOAD_LIST_VIEW_AS_CSV = 'CLEAR_DOWNLOAD_LIST_VIEW_AS_CSV';
