import {get} from 'lodash';
import api from './BaseApi';

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = async (endpoint, schema, method, body) => {
  return await api.fetch(endpoint, {
    method,
    body
  });
};

export const CALL_API = 'Call API';

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let {endpoint, method, body} = callAPI;
  const {schema, types} = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (typeof method === 'undefined') {
    method = 'get';
  } else if (typeof method !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  if (typeof body !== 'object') {
    body = null;
  }

  // TODO: remove as customerId should be part of the token
  const customerId = get(store.getState(), 'user.userInformation.customerId', null);
  if (customerId) {
    if (endpoint.indexOf('?') === -1) {
      endpoint = `${endpoint}?customerId=${customerId}`;
    } else {
      endpoint = `${endpoint}&customerId=${customerId}`;
    }
  }


  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({type: requestType}));

  return callApi(endpoint, schema, method, body).then(
    response => next(actionWith({
      response,
      type: successType
    })),
    error => next(
      actionWith({
        type: failureType,
        error: error.message || 'Something bad happened'
      })
    )
  );
};
