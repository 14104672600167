import React from 'react';
import ReactGA from 'react-ga';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import MultipleSelectFilter from './MultipleSelectFilter';

export default class StandardFilter extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })),
    value: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    name: '',
    id: '',
    options: [],
    label: '',
    value: []
  }

  onChange= value => {
    ReactGA.event({
      category: 'Filter',
      action: this.props.name,
      label: 'Multiple Select'
    });

    this.props.onChange(value);
  }

  renderFilter= () => {
    const hasListOfOptions = (this.props.options).length > 0;

    if (hasListOfOptions && this.props.options) {
      return (
        <MultipleSelectFilter
          options={this.props.options}
          label={I18n.t(this.props.label)}
          value={this.props.value}
          onChange={this.onChange}
          isMobile={false}
        />);
    }

    return (
      <TextField
        placeholder={I18n.t(this.props.label)}
        onChange={this.ondChange}
        fullWidth
      />);
  }

  render() {
    return (
      <ListItem id={this.props.id}>
        {this.renderFilter()}
      </ListItem>
    );
  }
}

