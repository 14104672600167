const PULL_NOTIFICATIONS_REQUEST = 'NOTIFICATIONS/PULL_REQUEST';
const PULL_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS/PULL_SUCCESS';
const PULL_NOTIFICATIONS_FAIL = 'NOTIFICATIONS/PULL_ERROR';

const READ_NOTIFICATIONS_REQUEST = 'NOTIFICATIONS/READ_REQUEST';
const READ_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS/READ_SUCCESS';
const READ_NOTIFICATIONS_FAIL = 'NOTIFICATIONS/READ_FAIL';

const READ_NOTIFICATIONS_COMMIT = 'NOTIFICATIONS/READ_COMMIT';

const LOAD_PREVIOUS_NOTIFICATIONS_REQUEST = 'NOTIFICATIONS/LOAD_PREVIOUS_REQUEST';
const LOAD_PREVIOUS_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS/LOAD_PREVIOUS_SUCCESS';
const LOAD_PREVIOUS_NOTIFICATIONS_FAIL = 'NOTIFICATIONS/LOAD_PREVIOUS_FAIL';

const DELETE_NOTIFICATIONS_REQUEST = 'NOTIFICATIONS/DELETE_REQUEST';
const DELETE_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS/DELETE_SUCCESS';
const DELETE_NOTIFICATIONS_FAIL = 'NOTIFICATIONS/DELETE_FAIL';

const NOTIFICATIONS_PANEL_OPEN = 'NOTIFICATIONS/PANEL_OPEN';
const NOTIFICATIONS_PANEL_CLOSE = 'NOTIFICATIONS/PANEL_CLOSE';

const RESET = 'NOTIFICATIONS/RESET';

export default {
  PULL_NOTIFICATIONS_REQUEST,
  PULL_NOTIFICATIONS_SUCCESS,
  PULL_NOTIFICATIONS_FAIL,

  READ_NOTIFICATIONS_REQUEST,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAIL,

  READ_NOTIFICATIONS_COMMIT,

  LOAD_PREVIOUS_NOTIFICATIONS_REQUEST,
  LOAD_PREVIOUS_NOTIFICATIONS_SUCCESS,
  LOAD_PREVIOUS_NOTIFICATIONS_FAIL,

  DELETE_NOTIFICATIONS_REQUEST,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAIL,

  NOTIFICATIONS_PANEL_OPEN,
  NOTIFICATIONS_PANEL_CLOSE,

  RESET
};
