/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {I18n} from 'react-redux-i18n';
import {Icon} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TruckIcon from '../../images/truck-icon.svg';

export default class Cards extends Component {
  renderEventsGenerated(eventsGenerated) {
    return (
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card className="kpi__card">
          {/* TODO: FIX:
            https://v1-0-0.material-ui.com/api/card-media/
            Material-UI: either `image` or `src` property must be specified.
            */}
          <CardMedia className="kpi__iconcont kpi__iconcont-totalevents">
            <img src={TruckIcon} alt="TruckIcon" className="kpi__icon" />
          </CardMedia>
          <CardContent>
            <label id="totalEventsGenerated">{I18n.t('page.dashboard.totalEventsGenerated')}</label>
            <h1>{eventsGenerated}</h1>
          </CardContent>
        </Card>
      </Grid>
    );  
  }

  renderAvgDistance(avgDistance) {
    return (
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card className="kpi__card">
          <CardMedia className="kpi__iconcont kpi__iconcont-averagedistance">
            <img src={TruckIcon} alt="TruckIcon" className="kpi__icon" />
          </CardMedia>
          <CardContent>
            <label>{I18n.t(`page.dashboard.avg${this.props.systemMeasure}`)}</label>
            <h1>{avgDistance}</h1>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  renderTotalCoaching(totalCoaching) {
    return (
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card className="kpi__card">
          <CardMedia className="kpi__iconcont kpi__iconcont-totalcoachings">
            <Icon className="kpi__icon">people</Icon>
          </CardMedia>
          <CardContent>
            <label>{I18n.t('page.dashboard.totalCoaching')}</label>
            <h1>{totalCoaching}</h1>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  renderTotalReviewed(totalReviewed) {
    return (
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card className="kpi__card">
          <CardMedia className="kpi__iconcont kpi__iconcont-totalreviewed">
            <Icon className="kpi__icon">check</Icon>
          </CardMedia>
          <CardContent>
            <label>{I18n.t('page.dashboard.totalReviewed')}</label>
            <h1>{totalReviewed}</h1>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  render() {
    const {
      eventsGenerated,
      avgDistance,
      totalCoaching,
      totalReview,
    } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={11} xl={10} className="kpi__container">
          <Grid container spacing={16}>
            {this.renderEventsGenerated(eventsGenerated)}
            {this.renderAvgDistance(avgDistance)}
            {this.renderTotalCoaching(totalCoaching)}
            {this.renderTotalReviewed(totalReview)}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
