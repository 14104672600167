import React from 'react';
import {connect} from 'react-redux';
import {setCoach} from '../../actions/player';
import PreviouslyReviews from '../component/PreviouslyReviews';

class PreviouslyReviewed extends React.PureComponent {
  render() {
    return (
      <PreviouslyReviews
        textSearch={this.props.textSearch}
        currentPage={this.props.currentPage}
        onChanteTextSearch={this.props.onChanteTextSearch}
        onChangePage={this.props.onChangePage}
        onSearchReview={this.props.onSearchReview}
        onChangeReviewSort={this.props.onChangeReviewSort}
        onLoadReview={this.props.onLoadReview}
        getSortFieldDirection={this.props.getSortFieldDirection}
        onChangeHeaderVisibility={this.props.onChangeHeaderVisibility}
        isSameSortField={this.props.isSameSortField}
        searchingReviews={this.props.searchingReviews}
        searchingMoreReviews={this.props.searchingMoreReviews}
        previouslyReviews={this.props.previouslyReviews}
        previouslyReviewsTotal={this.props.previouslyReviewsTotal}
        isMobile={this.props.isMobile}
        {...this.props}
      />
    );
  }
}


const mapStateToProps = state => {
  const {player} = state;
  return {
    ...player,
    ...state.i18n.locale
  };
};

export default connect(mapStateToProps, {setCoach})(PreviouslyReviewed);

